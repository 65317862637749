import store from '../store';
const getterSetter = name => ( ...args) => {
    if(!args.length){
        return localStorage.getItem(name)
    }
    else if(args[0] === false){
        return localStorage.removeItem(name);
    }
    else {
        return localStorage.setItem(name, args[0]);
    }
}
export const token = getterSetter('token');

const preferenceType = {
    initHelp: 'initHelp',
    projectRooms: 'projectRooms',
    projectInsp: 'projectInsp',
    projectDetails: 'projectDetails',
    projectStyle: 'projectStyle',
}

const preferences = (name, value) => {
    const userId = store.getState().authentication.user.id;
    const key =  btoa(`prefs$${userId}`);
    
    let data = localStorage.getItem(key) ||  btoa('{}');
    data = atob(data);
    data = JSON.parse(data);
    if(!Object.isDefined(value)){
        return !!data[name];
    }
    else {
        data[name] = value;
        localStorage.setItem(key, btoa(JSON.stringify(data)));
    }
}

export const LocalData = {
    token: token,
    email: getterSetter('email'),
    provider: getterSetter('provider'),
    preferences: preferences,
    preferenceType: preferenceType
}