import { FieldRuleTypes, FieldTypes } from '../components/Form/config';
import { StateOptions } from '../config';

export const RoleIds = Object.freeze({
    PUBLIC: 0,
    ADMIN: 1,
    CLIENT: 2, 
    ALL: [0, 1, 2]
});

export const RoleNames = Object.freeze({
    0: 'PUBLIC',
    1: 'ADMIN', 
    2: 'CLIENT',
});

export const RoleOptions = [
    {value: 1, text: 'Administrator'},
    {value: 2, text: 'Client'}
]

export const UserModel = {
    subdivision: {
        name: 'subdivision',
        displayName: 'Subdivison',
        type: FieldTypes.INPUT,
        size: 12
    },
    lotNumber: {
        name: 'lotNumber',
        displayName: 'Lot Number',
        type: FieldTypes.INPUT,
        size: 12
    },
    homePlanName: {
        name: 'homePlanName',
        displayName: 'Home Plan name',
        type: FieldTypes.INPUT,
        size: 12
    },
    username: {
        name: 'username',
        displayName: 'Username',
        validation: { required: true, rule: FieldRuleTypes.USERNAME},
        type: FieldTypes.INPUT,
        size: 12,
     },
    email: {
        name: 'email',
        displayName: 'Email',
        validation: { required: true, rule: FieldRuleTypes.EMAIL},
        type: FieldTypes.EMAIL,
        placeholder: 'Email',
        size: 12,
    },
    contactModeId: {
        name: 'contactModeId',
        displayName: 'Preferred Mode of Contact',
        type: FieldTypes.RADIOGROUP,
        size: 24,
    },
    newsletters: {
        name: 'newsletters',
        displayName: 'Newsletter Subscription',
        type: FieldTypes.SWITCH,
        size: 24,
        props: { valuePropName: 'checked'}
    },
    roleId: {
        name: 'roleId',
        displayName: 'Role',
        validation: { required: true},
        size: 12,
        type: FieldTypes.SELECT,
        optionFilterProp: 'children',
        options: RoleOptions
    },
    builder: {
        name: 'builderId',
        displayName: 'Builder',
        validation: { required: true},
        size: 12,
        type: FieldTypes.SELECT,
    },
    remember: {
        name: 'remember',
        props: { valuePropName: 'checked'}
    },
    firstName: {
        name: 'firstName',
        displayName: 'First Name',
        validation: { required: true },
        type: FieldTypes.INPUT,
        size: 12,
    },
    lastName: {
        name: 'lastName',
        displayName: 'Last Name',
        validation: { required: true },
        type: FieldTypes.INPUT,
        size: 12,
    },
    birthDate: {
        name: 'birthDate',
        displayName: 'Birth Date',
        validation: { rule:  FieldRuleTypes.DATEINPAST},
        type: FieldTypes.DATE,
        size: 12,
    },
    phone: {
        name: 'phoneNumber',
        displayName: 'Contact Number',
        validation: { required: true, rule: FieldRuleTypes.PHONE},
        type: FieldTypes.INPUT,
        size: 12,
    },
    address1: {
        name: 'address1',
        displayName: 'Address Line 1',
        type: FieldTypes.INPUT,
        size: 12,
    },
    address2: {
        name: 'address2',
        displayName: 'Address Line 2',
        type: FieldTypes.INPUT,
        size: 12,
    },
    city: {
        name: 'city',
        displayName: 'City',
        type: FieldTypes.INPUT,
        size: 8,
    },
    state: {
        name: 'state',
        displayName: 'State',
        size: 8,
        type: FieldTypes.SELECT,
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
        filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0,
        options: StateOptions
    },
    zip: {
        name: 'zip',
        displayName: 'Zip',
        validation: { rule: FieldRuleTypes.ZIP},
        type: FieldTypes.INPUT,
        size: 8,
    },
    password: (validationFn) => {
        return validationFn? {
            name: 'password',
            displayName: 'Your Password',
            validation: { required: true, rule: [FieldRuleTypes.PASSWORD, validationFn]},
        } : {
            type: 'password',
            name: 'password',
            displayName: 'Your Password', 
            placeholder: 'Password'
        }
    },
    confirm: (validationFn) => {
        return {
            name: 'confirmPassword',
            displayName: 'Your Password Again',
            validation: { required: true, rule: validationFn},
        }
    },
    owner2FirstName: {
        name: 'owner2FirstName',
        displayName: 'First Name',
        validation: { required: false },
        type: FieldTypes.INPUT,
        size: 12,
    },
    owner2LastName: {
        name: 'owner2LastName',
        displayName: 'Last Name',
        validation: { required: false },
        type: FieldTypes.INPUT,
        size: 12,
    },
    owner2Email: {
        name: 'owner2Email',
        displayName: 'Email',
        validation: { required: false, rule: FieldRuleTypes.EMAIL},
        type: FieldTypes.EMAIL,
        size: 12,
    },
    owner2PhoneNumber: {
        name: 'owner2PhoneNumber',
        displayName: 'Contact Number',
        validation: { rule: FieldRuleTypes.PHONE},
        type: FieldTypes.INPUT,
        size: 12,
    }
}