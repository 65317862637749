import React, { Component } from "react";
import { Row, Col } from 'antd';
import FormPasswordChange from '../containers/FormPasswordChange';

class PasswordChange extends Component {
    state = {};

    render () {  
        return (
            <Row>
                <Col lg={12} xl={10}>
                    <h1>Lets create you a new password</h1>
                    {/*<h4>Strong passwords include numbers, letters and punctuation marks.</h4>*/}
                    <FormPasswordChange params={this.props.params}/>     
                </Col>       
            </Row>
        );
      }
}


export default PasswordChange;