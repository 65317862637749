import React from "react";
import { Input, Icon } from 'antd';

class FilterText extends React.Component{
    state = {value: null}
    searchInput = undefined;

    handleChange = (e) => {
        this.setState({value: e.target.value})
    }
    
    handleSearch = () =>{
        const { value } = this.state;
        if(value){
            this.props.setSelectedKeys(value ? value : undefined);
            this.props.confirm();    
        }
        else {
            this.props.clearFilters();
        }
    }

    render () {
        const { clearFilters, label } = this.props;
        const { value } = this.state;
        return (
            <div>
                <Input
                    ref={ref => this.searchInput = ref}
                    placeholder={`Search ${label}`}
                    value={value}
                    onChange={this.handleChange}
                    onPressEnter={this.handleSearch}/>
                <div className="ant-table-filter-dropdown-btns">
                    <span onClick={this.handleSearch} 
                        className="ant-table-filter-dropdown-link confirm">
                            Ok
                    </span>
                    <span onClick={clearFilters} 
                        className="ant-table-filter-dropdown-link clear">
                            Reset
                    </span>
                </div>
            </div>
        );
    }
}

const FilterIcon = filtered => (
    <Icon 
        type="filter" 
        theme="filled"
        className={filtered && 'filtered'} />
);

export const getFiltersProps = (item, filterRef) => {
    const filter = item.filter;

    let filterProps  =  null;
    if(Array.isArray(filter)){
        filterProps = {
            filters: filter, 
            filterMultiple: true,
            filterIcon: FilterIcon,
        };
    
    }
    else if(filter) {
        filterProps = {
            filterIcon: FilterIcon,
            filterDropdown: (props) => (
                <FilterText 
                    ref={ref => filterRef = ref} 
                    {...props} 
                    label={item.title}/>
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => filterRef.searchInput.select());
                }
            },
        }

    }
    return filterProps;
};