import React, { Component } from "react";
import { connect } from 'react-redux';
import Sider  from './Project.Sider';
import { Row, Col} from 'antd';
import { ProjectRoutes } from './index';
import { ProjectActions } from '../actions';
import './quiz.scss';
import { history } from '../helpers';

class Project extends Component {
    componentWillMount() {
        const { projectId } = this.props.user;
        this.props.get(projectId);
        history.push(`/project/${projectId}`)
    }
    
    render () {       
        const { user: { projectId } } = this.props;
        return ( 
            <Row type="flex" className="sider-holder">
                <Col xl={5}>                    
                    <Sider key={projectId} projectId={projectId}></Sider>
                </Col>
                <Col xl={19}>
                    <ProjectRoutes projectId={projectId}/>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = ( {authentication }) => {
    return {
          loggedIn: authentication.loggedIn,
          user: authentication.user,
      };
};
const mapDispatchToProps  = dispatch => {
    return {
        get: (id) => dispatch(ProjectActions.getAllData(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Project);