import React from "react";

const htmlString = '<BODY LANG="en-US" LINK="#000000" DIR="LTR">\n' +
    '<DIV TYPE=HEADER>\n' +
    '\t<P STYLE="margin-bottom: 0.46in"><BR><BR>\n' +
    '\t</P>\n' +
    '</DIV>\n' +
    '<P ALIGN=CENTER STYLE="margin-top: 0.08in; margin-bottom: 0.17in; line-height: 115%"><A NAME="a184898"></A>\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=4 STYLE="font-size: 16pt"><B>Website\n' +
    'Privacy Policy</B></FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Last\n' +
    'modified: June 30, 2019</FONT></FONT></FONT></P>\n' +
    '<P ALIGN=LEFT STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%"><A NAME="a598115"></A>\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><B><U>Introduction</U></B></FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Dwellify,\n' +
    'LLC (<B>“Company”</B> or<B>&nbsp;”We”</B>) respect your\n' +
    'privacy and are committed to protecting it through our compliance\n' +
    'with this policy.</FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>This\n' +
    'policy describes the types of information we may collect from you or\n' +
    'that you may provide when you visit the website www.dwellify.com (our\n' +
    '“<B>Website</B>”) and our practices for collecting, using,\n' +
    'maintaining, protecting, and disclosing that information.</FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>This\n' +
    'policy applies to information we collect:</FONT></FONT></FONT></P>\n' +
    '<UL>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>On\n' +
    '\tthis Website.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>In\n' +
    '\temail, text, and other electronic messages between you and this\n' +
    '\tWebsite.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Through\n' +
    '\tmobile and desktop applications you download from this Website,\n' +
    '\twhich provide dedicated non-browser-based interaction between you\n' +
    '\tand this Website.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>When\n' +
    '\tyou interact with our advertising and applications on third-party\n' +
    '\twebsites and services, if those applications or advertising include\n' +
    '\tlinks to this policy.</FONT></FONT></FONT></P>\n' +
    '</UL>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>It\n' +
    'does not apply to information collected by:</FONT></FONT></FONT></P>\n' +
    '<UL>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Us\n' +
    '\toffline or through any other means, including on any other website\n' +
    '\toperated by Company or any third party (including our affiliates and\n' +
    '\tsubsidiaries; or </FONT></FONT></FONT>\n' +
    '\t</P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Any\n' +
    '\tthird party (including our affiliates and subsidiaries), including\n' +
    '\tthrough any application or content (including advertising) that may\n' +
    '\tlink to or be accessible from or on the Website.</FONT></FONT></FONT></P>\n' +
    '</UL>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Please\n' +
    'read this policy carefully to understand our policies and practices\n' +
    'regarding your information and how we will treat it. If you do not\n' +
    'agree with our policies and practices, your choice is not to use our\n' +
    'Website. By accessing or using this Website, you agree to this\n' +
    'privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy).\n' +
    'Your continued use of this Website after we make changes is deemed to\n' +
    'be acceptance of those changes, so please check the policy\n' +
    'periodically for updates. </FONT></FONT></FONT>\n' +
    '</P>\n' +
    '<P ALIGN=LEFT STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%"><A NAME="a1019988"></A>\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><B><U>Children\n' +
    'Under the Age of 16</U></B></FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Our\n' +
    'Website is not intended for children under 16 years of age. No one\n' +
    'under age 16 may provide any information to or on the Website. We do\n' +
    'not knowingly collect personal information from children under 16. If\n' +
    'you are under 16, do not use or provide any information on this\n' +
    'Website, register on the Website, make any purchases through the\n' +
    'Website, use any of the interactive features of this Website, or\n' +
    'provide any information about yourself to us, including your name,\n' +
    'address, telephone number, email address, or any screen name or user\n' +
    'name you may use. If we learn we have collected or received personal\n' +
    'information from a child under 16 without verification of parental\n' +
    'consent, we will delete that information. If you believe we might\n' +
    'have any information from or about a child under 16, please contact\n' +
    'us at <A HREF="mailto:hello@dwelllify.com">hello@dwelllify.com</A>. </FONT></FONT></FONT>\n' +
    '</P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>California\n' +
    'residents under 16 years of age may have additional rights regarding\n' +
    'the collection and sale of their personal information. Please see [Your California Privacy Rights]\n' +
    'for more information.</FONT></FONT></FONT></P>\n' +
    '<P ALIGN=LEFT STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%"><A NAME="a216371"></A>\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><B><U>Information\n' +
    'We Collect About You and How We Collect It</U></B></FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>We\n' +
    'collect several types of information from and about users of our\n' +
    'Website, including information:</FONT></FONT></FONT></P>\n' +
    '<UL>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>By\n' +
    '\twhich you may be personally identified, such as name, postal\n' +
    '\taddress, e-mail address, telephone number, social security number or\n' +
    '\tany other identifier by which you may be contacted online or offline\n' +
    '\t(“<B>personal information</B>”);</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>That\n' +
    '\tis about you but individually does not identify you; and</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>About\n' +
    '\tyour internet connection, the equipment you use to access our\n' +
    '\tWebsite, and usage details.</FONT></FONT></FONT></P>\n' +
    '</UL>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>We\n' +
    'collect this information:</FONT></FONT></FONT></P>\n' +
    '<UL>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Directly\n' +
    '\tfrom you when you provide it to us.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Automatically\n' +
    '\tas you navigate through the site. Information collected\n' +
    '\tautomatically may include usage details, IP addresses, and\n' +
    '\tinformation collected through cookies and other tracking\n' +
    '\ttechnologies.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>From\n' +
    '\tthird parties, for example, our business partners.</FONT></FONT></FONT></P>\n' +
    '</UL>\n' +
    '<P ALIGN=LEFT STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%"><A NAME="a782263"></A>\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><FONT COLOR="#000000"><SPAN STYLE="text-decoration: none"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><SPAN STYLE="font-weight: normal"><I>Information\n' +
    'You Provide to Us</I></SPAN></FONT></FONT></SPAN></FONT><B>  </B></FONT></FONT></FONT>\n' +
    '</P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>The\n' +
    'information we collect on or through our Website may include:</FONT></FONT></FONT></P>\n' +
    '<UL>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Information\n' +
    '\tthat you provide by filling in forms on our Website. </FONT></FONT></FONT>\n' +
    '\t</P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Records\n' +
    '\tand copies of your correspondence (including email addresses), if\n' +
    '\tyou contact us.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Your\n' +
    '\tresponses to surveys that we might ask you to complete for research\n' +
    '\tpurposes.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Details\n' +
    '\tof transactions you carry out through our Website and of the\n' +
    '\tfulfillment of your business. </FONT></FONT></FONT>\n' +
    '\t</P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Your\n' +
    '\tsearch queries on the Website.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><A NAME="_GoBack"></A>\n' +
    '\t<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Projects\n' +
    '\tcreated, images uploaded and social links shared with us. </FONT></FONT></FONT>\n' +
    '\t</P>\n' +
    '</UL>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>You\n' +
    'also may provide information to be published or displayed\n' +
    '(hereinafter, “<B>posted</B>”) on public areas of the Website, or\n' +
    'transmitted to other users of the Website or third parties\n' +
    '(collectively, “<B>User Contributions</B>”). Your User\n' +
    'Contributions are posted on and transmitted to others at your own\n' +
    'risk. Please be aware that no security measures are perfect or\n' +
    'impenetrable. Additionally, we cannot control the actions of other\n' +
    'users of the Website with whom you may choose to share your User\n' +
    'Contributions. Therefore, we cannot and do not guarantee that your\n' +
    'User Contributions will not be viewed by unauthorized persons.</FONT></FONT></FONT></P>\n' +
    '<P ALIGN=LEFT STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%"><A NAME="a683129"></A>\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><FONT COLOR="#000000"><SPAN STYLE="text-decoration: none"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><SPAN STYLE="font-weight: normal"><I>Information\n' +
    'We Collect Through Automatic Data Collection Technologies</I></SPAN></FONT></FONT></SPAN></FONT><B><I>\n' +
    ' </I></B></FONT></FONT></FONT>\n' +
    '</P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>As\n' +
    'you navigate through and interact with our Website, we may use\n' +
    'automatic data collection technologies to collect certain information\n' +
    'about your equipment, browsing actions, and patterns, including:</FONT></FONT></FONT></P>\n' +
    '<UL>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000">\n' +
    '\t<FONT FACE="Times New Roman, serif"><FONT SIZE=3>Details of your\n' +
    '\tvisits to our Website, including traffic data, location data, logs,\n' +
    '\tand other communication data and the resources that you access and\n' +
    '\tuse on the Website.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Information\n' +
    '\tabout your computer and internet connection, including your IP\n' +
    '\taddress, operating system, and browser type.</FONT></FONT></FONT></P>\n' +
    '</UL>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>We\n' +
    'also may use these technologies to collect information about your\n' +
    'online activities over time and across third-party websites or other\n' +
    'online services (behavioral tracking). The information we collect\n' +
    'automatically may be only statistical data and does not include\n' +
    'personal information, but we may maintain it or associate it with\n' +
    'personal information we collect in other ways or receive from third\n' +
    'parties. It helps us to improve our Website and to deliver a better\n' +
    'and more personalized service, including by enabling us to:</FONT></FONT></FONT></P>\n' +
    '<UL>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Estimate\n' +
    '\tour audience size and usage patterns.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Store\n' +
    '\tinformation about your preferences, allowing us to customize our\n' +
    '\tWebsite according to your individual interests.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Speed\n' +
    '\tup your searches.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Recognize\n' +
    '\tyou when you return to our Website.</FONT></FONT></FONT></P>\n' +
    '</UL>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>The\n' +
    'technologies we use for this automatic data collection may include:</FONT></FONT></FONT></P>\n' +
    '<UL>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><B>Cookies\n' +
    '\t(or browser cookies).</B> A cookie is a small file placed on the\n' +
    '\thard drive of your computer. You may refuse to accept browser\n' +
    '\tcookies by activating the appropriate setting on your browser.\n' +
    '\tHowever, if you select this setting you may be unable to access\n' +
    '\tcertain parts of our Website. Unless you have adjusted your browser\n' +
    '\tsetting so that it will refuse cookies, our system will issue\n' +
    '\tcookies when you direct your browser to our Website. </FONT></FONT></FONT>\n' +
    '\t</P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><B>Flash\n' +
    '\tCookies.</B> Certain features of our Website may use local stored\n' +
    '\tobjects (or Flash cookies) to collect and store information about\n' +
    '\tyour preferences and navigation to, from, and on our Website. Flash\n' +
    '\tcookies are not managed by the same browser settings as are used for\n' +
    '\tbrowser cookies. For information about managing your privacy and\n' +
    '\tsecurity settings for Flash cookies, see Choices About How We Use and Disclose Your Information.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><B>Web\n' +
    '\tBeacons.</B> Pages of our the Website and our e-mails may contain\n' +
    '\tsmall electronic files known as web beacons (also referred to as\n' +
    '\tclear gifs, pixel tags, and single-pixel gifs) that permit the\n' +
    '\tCompany, for example, to count users who have visited those pages or\n' +
    '\topened an email and for other related website statistics (for\n' +
    '\texample, recording the popularity of certain website content and\n' +
    '\tverifying system and server integrity).  </FONT></FONT></FONT>\n' +
    '\t</P>\n' +
    '</UL>\n' +
    '<P ALIGN=LEFT STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%"><A NAME="a612118"></A>\n' +
    '<BR><BR>\n' +
    '</P>\n' +
    '<P ALIGN=LEFT STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><B><U>Third-Party\n' +
    'Use of Cookies and Other Tracking Technologies</U></B></FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Some\n' +
    'content or applications, including advertisements, on the Website are\n' +
    'served by third-parties, including advertisers, ad networks and\n' +
    'servers, content providers, and application providers. These third\n' +
    'parties may use cookies alone or in conjunction with web beacons or\n' +
    'other tracking technologies to collect information about you when you\n' +
    'use our website. The information they collect may be associated with\n' +
    'your personal information or they may collect information, including\n' +
    'personal information, about your online activities over time and\n' +
    'across different websites and other online services. They may use\n' +
    'this information to provide you with interest-based (behavioral)\n' +
    'advertising or other targeted content. </FONT></FONT></FONT>\n' +
    '</P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>We\n' +
    'do not control these third parties’ tracking technologies or how\n' +
    'they may be used. If you have any questions about an advertisement or\n' +
    'other targeted content, you should contact the responsible provider\n' +
    'directly. </FONT></FONT></FONT>\n' +
    '</P>\n' +
    '<P ALIGN=LEFT STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%"><A NAME="a183824"></A>\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><B><U>How\n' +
    'We Use Your Information</U></B></FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>We\n' +
    'use information that we collect about you or that you provide to us,\n' +
    'including any personal information:</FONT></FONT></FONT></P>\n' +
    '<UL>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>To\n' +
    '\tpresent our Website and its contents to you.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>To\n' +
    '\tprovide you with information, products, or services that you request\n' +
    '\tfrom us.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>To\n' +
    '\tfulfill any other purpose for which you provide it.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>To\n' +
    '\tcarry out our obligations and enforce our rights arising from any\n' +
    '\tcontracts entered into between you and us, including for billing and\n' +
    '\tcollection.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>To\n' +
    '\tnotify you about changes to our Website or any products or services\n' +
    '\twe offer or provide though it.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>In\n' +
    '\tany other way we may describe when you provide the information.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>For\n' +
    '\tany other purpose with your consent.</FONT></FONT></FONT></P>\n' +
    '</UL>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>We\n' +
    'may also use your information to contact you about our own and\n' +
    'third-parties’ goods and services that may be of interest to you. </FONT></FONT></FONT>\n' +
    '</P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>We\n' +
    'may use the information we have collected from you to enable us to\n' +
    'display advertisements to our advertisers’ target audiences. Even\n' +
    'though we do not disclose your personal information for these\n' +
    'purposes without your consent, if you click on or otherwise interact\n' +
    'with an advertisement, the advertiser may assume that you meet its\n' +
    'target criteria.</FONT></FONT></FONT></P>\n' +
    '<P ALIGN=LEFT STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%"><A NAME="a998196"></A>\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><B><U>Disclosure\n' +
    'of Your Information</U></B></FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>We\n' +
    'may disclose aggregated information about our users, and information\n' +
    'that does not identify any individual, without restriction. </FONT></FONT></FONT>\n' +
    '</P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>We\n' +
    'may disclose personal information that we collect or you provide as\n' +
    'described in this privacy policy:</FONT></FONT></FONT></P>\n' +
    '<UL>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>To\n' +
    '\tour subsidiaries and affiliates.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>To\n' +
    '\tcontractors, service providers, and other third parties we use to\n' +
    '\tsupport our business and who are bound by contractual obligations to\n' +
    '\tkeep personal information confidential and use it only for the\n' +
    '\tpurposes for which we disclose it to them.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>To\n' +
    '\ta buyer or other successor in the event of a merger, divestiture,\n' +
    '\trestructuring, reorganization, dissolution, or other sale or\n' +
    '\ttransfer of some or all of Dwellify, LLC’s assets, whether as a\n' +
    '\tgoing concern or as part of bankruptcy, liquidation, or similar\n' +
    '\tproceeding, in which personal information held by Dwellify, LLC\n' +
    '\tabout our Website users is among the assets transferred.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>To\n' +
    '\tthird parties to market their products or services to you if you\n' +
    '\thave not opted out of these disclosures. </FONT></FONT></FONT>\n' +
    '\t</P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>To\n' +
    '\tfulfill the purpose for which you provide it. </FONT></FONT></FONT>\n' +
    '\t</P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>For\n' +
    '\tany other purpose disclosed by us when you provide the information.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>With\n' +
    '\tyour consent.</FONT></FONT></FONT></P>\n' +
    '</UL>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>We\n' +
    'may also disclose your personal information:</FONT></FONT></FONT></P>\n' +
    '<UL>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>To\n' +
    '\tcomply with any court order, law, or legal process, including to\n' +
    '\trespond to any government or regulatory request.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>To\n' +
    '\tenforce or apply our terms of use or terms of sale and other\n' +
    '\tagreements, including for billing and collection purposes.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>If\n' +
    '\twe believe disclosure is necessary or appropriate to protect the\n' +
    '\trights, property, or safety of Dwellify, LLC, our customers, or\n' +
    '\tothers. This includes exchanging information with other companies\n' +
    '\tand organizations for the purposes of fraud protection and credit\n' +
    '\trisk reduction.</FONT></FONT></FONT></P>\n' +
    '</UL>\n' +
    '<P ALIGN=LEFT STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%"><A NAME="a741158"></A>\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><B><U>Choices\n' +
    'About How We Use and Disclose Your Information</U></B></FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>We\n' +
    'strive to provide you with choices regarding the personal information\n' +
    'you provide to us. We have created mechanisms to provide you with the\n' +
    'following control over your information: </FONT></FONT></FONT>\n' +
    '</P>\n' +
    '<UL>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><B>Tracking\n' +
    '\tTechnologies and Advertising.</B> You can set your browser to refuse\n' +
    '\tall or some browser cookies, or to alert you when cookies are being\n' +
    '\tsent. To learn how you can manage your Flash cookie settings, visit\n' +
    '\tthe Flash player settings page on Adobe’s website. If you disable\n' +
    '\tor refuse cookies, please note that some parts of this site may then\n' +
    '\tbe inaccessible or not function properly.</FONT></FONT></FONT></P>\n' +
    '\t<LI><P STYLE="margin-top: 0.08in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><B>Disclosure\n' +
    '\tof Your Information for Third-Party Advertising.</B> If you do not\n' +
    '\twant us to share your personal information with unaffiliated or\n' +
    '\tnon-agent third parties for promotional purposes, you can opt-out by\n' +
    '\tchecking the relevant box located on the form on which we collect\n' +
    '\tyour data. </FONT></FONT></FONT>\n' +
    '\t</P>\n' +
    '</UL>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>We\n' +
    'do not control third parties’ collection or use of your information\n' +
    'to serve interest-based advertising. However these third parties may\n' +
    'provide you with ways to choose not to have your information\n' +
    'collected or used in this way. You can opt out of receiving targeted\n' +
    'ads from members of the Network Advertising Initiative (“<B>NAI</B>”)\n' +
    'on the NAI’s website.</FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>California\n' +
    'residents may have additional personal information rights and\n' +
    'choices. Please see [Your California Privacy Rights] for more\n' +
    'information.</FONT></FONT></FONT></P>\n' +
    '<P ALIGN=LEFT STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%"><A NAME="a820658"></A>\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><B><U>Your\n' +
    'California Privacy Rights</U></B></FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>If\n' +
    'you are a California resident, California law may provide you with\n' +
    'additional rights regarding our use of your personal information.</FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>California’s\n' +
    '“Shine the Light” law (Civil Code Section § 1798.83) permits\n' +
    'users of our App that re California residents to request certain\n' +
    'information regarding our disclosure of personal information to third\n' +
    'parties for their direct marketing purposes. To make such a request,\n' +
    'please send an email to hello@dwellify.com.</FONT></FONT></FONT></P>\n' +
    '<P ALIGN=LEFT STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%"><A NAME="a539528"></A>\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><B><U>Data\n' +
    'Security</U></B></FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>We\n' +
    'have implemented measures designed to secure your personal\n' +
    'information from accidental loss and from unauthorized access, use,\n' +
    'alteration, and disclosure. Any payment transactions  will be\n' +
    'encrypted using SSL technology. </FONT></FONT></FONT>\n' +
    '</P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>The\n' +
    'safety and security of your information also depends on you. Where we\n' +
    'have given you (or where you have chosen) a password for access to\n' +
    'certain parts of our Website, you are responsible for keeping this\n' +
    'password confidential. We ask you not to share your password with\n' +
    'anyone. </FONT></FONT></FONT>\n' +
    '</P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>Unfortunately,\n' +
    'the transmission of information via the internet is not completely\n' +
    'secure. Although we do our best to protect your personal information,\n' +
    'we cannot guarantee the security of your personal information\n' +
    'transmitted to our Website. Any transmission of personal information\n' +
    'is at your own risk. We are not responsible for circumvention of any\n' +
    'privacy settings or security measures contained on the Website. </FONT></FONT></FONT>\n' +
    '</P>\n' +
    '<P ALIGN=LEFT STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%"><A NAME="a286592"></A>\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><B><U>Changes\n' +
    'to Our Privacy Policy</U></B></FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>It\n' +
    'is our policy to post any changes we make to our privacy policy on\n' +
    'this page with a notice that the privacy policy has been updated on\n' +
    'the Website home page. The date the privacy policy was last revised\n' +
    'is identified at the top of the page. You are responsible for\n' +
    'ensuring we have an up-to-date active and deliverable email address\n' +
    'for you, and for periodically visiting our Website and this privacy\n' +
    'policy to check for any changes.</FONT></FONT></FONT></P>\n' +
    '<P ALIGN=LEFT STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%"><A NAME="a955470"></A>\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3><B><U>Contact\n' +
    'Information</U></B></FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>To\n' +
    'ask questions or comment about this privacy policy and our privacy\n' +
    'practices, contact us at: </FONT></FONT></FONT>\n' +
    '</P>\n' +
    '<P STYLE="margin-bottom: 0.14in; line-height: 115%"><FONT COLOR="#000000"><FONT FACE="Times New Roman, serif"><FONT SIZE=3>hello@dwellify.com.</FONT></FONT></FONT></P>\n' +
    '<P STYLE="margin-top: 0.08in; margin-bottom: 0.14in; line-height: 115%">\n' +
    '<FONT COLOR="#000000"> </FONT>\n' +
    '</P>\n' +
    '<DIV TYPE=FOOTER>\n' +
    '\t<P STYLE="margin-bottom: 0.11in"><BR><BR>\n' +
    '\t</P>\n' +
    '</DIV>\n';

function Policy(props) {
    return (
        <div dangerouslySetInnerHTML={{ __html: htmlString }} />
    );
}

export default Policy;