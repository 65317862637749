import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Collapse } from 'antd';
import memoize from 'memoize-one';
import FormQuiz from '../../containers/FormQuiz';
import { ProjectActions as actions } from '../../actions';

const { Panel } = Collapse;

const initSteps = (model) => {
    let group = -1;
    const keys = Object.keys(model);
    const steps = keys
        .map((step, idx) => {
            const label = model[step].displayName;
            if (label) {
                group++;
            }
            return { 
                label, 
                group,
            };
        });
    steps.push({ 
        label: 'Result', 
        group: ++group,
    });
    return steps;
};

class AdminProjectStyle extends Component {
    unmounted = false;
    state = { steps: [], currentStep: -1, currentGroup: 0 };
 
    transformModel = memoize((model) => {
        const res = [];
        let thisStep = {};
        model.forEach((step, index) => {
            // Skip the "Start" and "Result" step
            if (index === model.length-1) { return; }
            if (index === 0) { return; }
            if (step.displayName) {
                thisStep = { ...step };
                res.push(thisStep);
            } else {
                thisStep.fields = [
                    ...thisStep.fields, 
                    ...step.fields
                ];
            }
        });
        return res;
    });

    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    componentDidMount() {
        actions.getQuizConfig('quiz-style')
        .then(
            (model) => {
                const steps = initSteps(model || {});
                if(!this.unmounted)
                this.setState({ model, steps });
            }
        );
    }
    
    componentWillUnmount() {
        this.unmounted = true;
    }

    static getDerivedStateFromProps(nextProps, nextState) {
        const newState = {};
        if ('quiz-style' in nextProps) {
            newState.currentStep = nextProps.quiz.step || 0;
        }
        if ('params' in nextProps && nextProps.params.id !== nextState.projectId) { newState.projectId = parseFloat(nextProps.params.id); }
        return newState;
    }

    clear = () => {
        const values = {};
        const { retakeQuiz, path, quiz } = this.props;
        Object.keys(quiz).forEach(item => (values[item] = null));

        retakeQuiz(this.state.projectId, values, path)
        .then(() => this.goto(0));
    }
      
    render() {
        const { model, steps, projectId } = this.state;
        const { project } = this.props;
        
        if (steps.length === 0) { return null; }
        const thisModel = this.transformModel(model);
        return (
          <Collapse accordion bordered>
            {thisModel.map((step, index) => (
              <Panel 
                  header={step.displayName}
                  key={index}
              >
                <FormQuiz 
                    disabled={project.disabled}
                    ref={(inst) => { this.form = inst; }} 
                    id={projectId}
                    quizName='quiz-style'
                    initialValues={project['quiz-style']}
                    model={step.fields}
                />
              </Panel>                   
                ))}
          </Collapse>
        );
      }
}

const mapStateToProps = ({ project, router }) => ({ 
        project,
        path: router.location.pathname,
    });

const mapDispatchToProps = dispatch => ({
        setQuizAnswers: (id, data) => dispatch(
                actions.setQuizAnswers(id, 'quiz-style', data)               
            ),
        retakeQuiz: (id, data, path) => dispatch(actions.setQuizAnswers(id, 'quiz-style', data))
    });

export default connect(mapStateToProps, mapDispatchToProps)(AdminProjectStyle);
