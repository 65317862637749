import React, { Component } from "react"
import { Button, Row, Col, Modal } from 'antd';
import { UserActions as actions, BuilderActions } from '../actions';
import { UserModel as model } from '../models';

import Form, { FormItem } from '../components/Form';

class Signup extends Component {

    state = { 
        confirmDirty: false, 
        builderOptions: [] 
    }

    handleSubmit = (values) => {
        const { toggleModal } = this.props;
        values.confirm = undefined;

        return actions.register(values)
            .then(() => {
                toggleModal(true);
            });
    }

    componentDidMount() {
        return BuilderActions.list()
            .then(builderData => {
                const builderOptions = builderData.map(e => {
                    return { text: e.name, value: e.id };
                });
                this.setState({ builderOptions });
            });
    }

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }

    validatePasswordConfirm = (rule, value, callback) => {
        if (value && value !== this.form.getFieldValue('password')) {
            callback(`Password doesn't match`);
        } else {
            callback();
        }
    }

    validatePassword = (rule, value, callback) => {
        if (value && this.state.confirmDirty) {
            this.form.validateFields(['confirm'])
        }
        callback();
    }

    render() {
        const { builderOptions } = this.state;
        const { toggleModal, visible } = this.props;
        
        
        const thisModel = {
            email: model.email,
            password: model.password(this.validatePassword),
            confirm: model.confirm(this.validatePasswordConfirm),
            first: model.firstName,
            last: model.lastName,
            builder: model.builder,
            subdivision: model.subdivision,
            lotNumber: model.lotNumber,
            homePlanName: model.homePlanName
        };

        return (
            <Modal
                title="Create new client:"
                visible={visible}
                onCancel={toggleModal}
                footer={null}
            >
                <Form initialValues={{}}
                    hideRequiredMark={true}
                    wrappedComponentRef={(inst) => this.form = inst}
                    onSubmit={this.handleSubmit}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <FormItem {...thisModel.first} />
                        </Col>
                        <Col span={12}>
                            <FormItem {...thisModel.last} />
                        </Col>
                        <Col span={24}>
                            <FormItem {...thisModel.email} />
                        </Col>
                        <Col span={24}>
                            <FormItem {...thisModel.builder} options={builderOptions} />
                        </Col>
                        <Col span={24}>
                            <FormItem {...thisModel.subdivision} />
                        </Col>
                        <Col span={24}>
                            <FormItem {...thisModel.lotNumber} />
                        </Col>
                        <Col span={24}>
                            <FormItem {...thisModel.homePlanName} />
                        </Col>
                    </Row>
                    <Button
                        block
                        type="primary"
                        size="large"
                        htmlType="submit"
                    >
                        Create Client
                    </Button>
                </Form>
            </Modal>
        );
    }
}

export default Signup;