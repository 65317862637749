import { push } from 'connected-react-router';
import { matchPath, generatePath } from 'react-router-dom';
import { ProjectRoutesConfig } from '../routes';
import { GONEXT } from '../constants';


const RoutesConfig = [ ...ProjectRoutesConfig]; 

export const routingMiddleware = store => next => (action) => {
    if (action.type === GONEXT) {
        const state = store.getState();
        const path = state.router.location.pathname;
        const propName = action.isBack ? 'back' : 'next';
        RoutesConfig.some((item) => {
            const res = matchPath(path, { path: item.path, exact: true });
            if (res) {                
                const nextPath = generatePath(item[propName], res.params);
                store.dispatch(push(nextPath));
                window.scrollTo(0, 0);
                return true;
            }
            return false;
        });        
    }
    return next(action);
};
