import './CheckboxGroup.scss';
import './CheckRadioGroup.scss';
import React from 'react';
import { Checkbox, Row, Col} from 'antd';

const CheckboxGroup = (options, props) => {
    if(options.length && options[0].image)
        return (
            <Checkbox.Group className="check-images">
                <Row gutter={32}>
                    {options.map((item, index) => {
                        if(!Object.isObject(item)){
                            item = {
                                url: item,
                                value: `${item}-${index}`,
                                size: 8
                            }
                        }
                        else if(!item.value && item.text)
                            item.value = item.text;
                        return (
                            <Col span={props.itemsize ? props.itemsize * 2 : 8} key={index} >
                                <Checkbox value={item.value} 
                                    valuePropName="value" 
                                    disabled={item.disabled}
                                    >
                                    <img src={item.image} alt=""/>
                                    <div className={item.required? 'required' : ''}>{item.text}</div>
                                </Checkbox>
                            </Col>
                        )
                    })}
                </Row>
            </Checkbox.Group>
        );
    return (
        <Checkbox.Group className="button">
            {options.map((item, index) => {
                if(!Object.isObject(item)){
                    item = {value: item, text: item}
                }
                return (
                    <Checkbox key={index} value={item.value} className="button">
                        <div className="ant-btn ant-btn-primary">{item.text}</div>
                    </Checkbox>
                )
            })}
        </Checkbox.Group>
    )
}

export  { CheckboxGroup };