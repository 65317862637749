import { BuilderService as service } from '../services';
import { showResponseError } from '../helpers';

const get = (id) => {
    return service.get(id)
        .then(
            data => data,
            error => showResponseError({ ...error, source: '[client.get]' })
        );
}

const list = (...params) => service.list(...params)
    .then(
        data => data,
        error => showResponseError({ ...error, source: '[client.list]' })
    );

const listAdvanced = (...params) => service.listAdvanced(...params)
    .then(
        data => data,
        error => showResponseError({ ...error, source: '[client.list]' })
    );

const create = values => service.create(values)
    .then(
        data => data,
        error => showResponseError({ ...error, source: '[client.create]' })
    );

const update = (id, values) => service.update(id, values)
    .then(
        data => data,
        error => showResponseError({ ...error, source: '[client.update]' })
    )
    .catch(error => showResponseError(error));

const remove = id => service.remove(id)
    .then(
        data => data,
        error => showResponseError({ ...error, source: '[client.remove]' })
    )
    .catch(error => showResponseError(error));

const getLogo = (name, id) => service.getLogo(name, id)
    .then(
        data => data,
        error => showResponseError({ ...error, source: '[client.get]' })
    )
    .catch(error => showResponseError(error));

export const BuilderActions = {
    list,
    listAdvanced,
    get,
    create,
    update,
    remove,
    getLogo,
};