import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Input, Button, Modal } from 'antd';
import ImageUpload from '../../components/ImageUpload';
import { UserService as service } from '../../services';

const { TextArea } = Input;

class DocumentsAddNew extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: undefined,
            description: '',
            descriptionChanged: false
        }
    }

    handeDescriptionChange = e => {
        this.setState({ 
            description: e.target.value,
         })
    }

    handleSuccess = (file) => {
        if(file)
            this.setState({ file })
    }

    handleOk = async () => {
        const { file, description } = this.state;

        if(description && file) {
            await service.updateFile(
                file.id, 
                { description }
            );
            
        }
        this.handleClose();
    }

    handleClose = () => {
        const { onClose } = this.props;
        this.setState({ file: undefined, description: ''});
        onClose();
    }

    render() {
        const { userId, visible } = this.props;
        const { description, file } = this.state;

        return (
            <Modal
                title="Upload file:"
                visible={visible}
                footer={null}
                onCancel={this.handleClose}
                destroyOnClose
            >
                <Row gutter={32}>
                    <Col span={24}>
                        <ImageUpload
                            className="admin-file-upload"
                            labelIdle='Drag & Drop File or <span class="filepond--label-action">Browse</span>'
                            allowMultiple={false}
                            postPath={`documents/user/${userId}`}
                            onSuccess={this.handleSuccess}
                        /> 
                    </Col>
                    <Col span={24}>
                        DESCRIPTION:
                    </Col>
                    <Col span={24}>
                        <TextArea 
                            value={description}
                            onChange={this.handeDescriptionChange} 
                            rows={8}
                            style={{marginBottom: 20}}
                            disabled={!file}
                            maxLength={120}
                        />
                    </Col>
                    <Col span={24}>
                        <Button 
                            type="default" 
                            onClick={this.handleClose}
                        >
                            CLOSE
                        </Button>
                        <Button 
                            type="primary right" 
                            onClick={this.handleOk}
                        >
                            SAVE
                        </Button>
                    </Col>
                </Row>
            </Modal>
        );
    }
}

DocumentsAddNew.propTypes = {
    userId: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
}

export default DocumentsAddNew;