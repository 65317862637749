import React, { Component } from 'react';
import 'filepond-polyfill';

import PropTypes from 'prop-types';
import { message } from 'antd';

import { FilePond, registerPlugin, FileStatus } from 'react-filepond';

import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import { ImageUrlDelimiter } from '../config';
import { token } from '../services';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import api from '../services/api';

registerPlugin(
    FilePondPluginImageExifOrientation, 
    FilePondPluginImagePreview,
    FilePondPluginFilePoster,
);

const ApiUrl = process.env.REACT_APP_API;

const StatusesDone = [FileStatus.PROCESSING_COMPLETE, FileStatus.IDLE];

const onError = (error) => {
    if (!error) { error = { error: 'Network Error' }; }
    if (!Object.isObject(error)) {
        error = JSON.parse(error);
    }
    message.error(`
        ${error.code || ''} 
        ${error.body || ''}  
        ${error.message || ''} 
        ${error.error || ''}
    `);
};

const handleDelete = (name, load, error) => api.delete(`/media/${name}`)
    .then((_) => {
        load();
        return true;
    })
    .catch((err) => {
        onError(err);
        return false;
    });

const ServerConfig = (postPath, tokenStr) => ({
        url: `${ApiUrl}/media`,
        load: {
            url: '/',
            method: 'GET',
            headers: {
                Authorization: `Bearer ${tokenStr}`
            },
        },
        process: {
            url: `/${postPath}`,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${tokenStr}`,
                Accept: 'application/json'
            },
            onerror: error => onError(error)
        },
        revert: (uniqueFileId, load, error) => {
            const serverData = JSON.parse(uniqueFileId.replace(/\\\//g, '/'));
            const name = `${serverData.id}${ImageUrlDelimiter}${serverData.name}`;
            return handleDelete(name, load, error);
        },
        remove: handleDelete,
        restore: null,
        fetch: null,
    });

class ImageUpload extends Component {
    pond = undefined;

    constructor(props) {
        super(props);
        this.state = {
            iniFiles: props.iniFiles
        };
    }

    componentWillReceiveProps(nextProps) {
        const { iniFiles } = nextProps;
        if (iniFiles) {            
            this.setState({ iniFiles });
        }
    }
    
    jsonToPond = (data) => {
        if (!data) { return []; }
        return data.map(item => ({
            source: `${item.id}${ImageUrlDelimiter}${item.name}`,
            options: {
                type: 'local',
            }
        }));
    }

    handleProcess = (error, file) => {
        const { onChange, onSuccess } = this.props;
        if (onChange) {
            const json = JSON.parse(file.serverId.replace(/\\\//g, '/'));
            return onChange(json);  
        }
        if(onSuccess){
            const json = JSON.parse(file.serverId.replace(/\\\//g, '/'));
            const allProcessed = this.pond.getFiles()
            .reduce(
                (done, file) => done && StatusesDone.indexOf(file.status) > -1,
                true
            );
            if(allProcessed){
                return onSuccess(json);
            }
        }
        return null;
    }

    handleRemove = (error, file) => {
        if (file.file.lastModified)// replaced file
            { return; }
        const { onChange } = this.props;
        if (onChange) {
            return onChange(null);  
        }
    }

    render() {
        const { postPath } = this.props;
        const { iniFiles } = this.state;
        const server = ServerConfig(postPath, token());
       
        const props = {
            key: postPath,
            server: server,
            onprocessfile: this.handleProcess,
            onremovefile: this.handleRemove
        }

        if(Array.isArray(iniFiles)){
            props.files = this.jsonToPond(iniFiles);
        }

        return (
            <FilePond 
                ref={ref => (this.pond = ref)}
                {...props} 
                {...this.props}
            />
        );
    }
}

ImageUpload.propTypes = {
    labelIdle: PropTypes.string,
    allowMultiple: PropTypes.bool,
    urls: PropTypes.array,
    imagePreviewMinHeight: PropTypes.number,
    imagePreviewMaxHeight: PropTypes.number,
    maxFiles: PropTypes.number,
    postPath: PropTypes.string.isRequired,
    iniFiles: PropTypes.array,
    onChange: PropTypes.func,
    onSuccess: PropTypes.func
}

ImageUpload.defaultProps = {
    labelIdle: 'Drag & Drop your files or <span class="filepond--label-action">Browse</span>',
    allowMultiple: false,
    urls: [],
    imagePreviewMinHeight: 44,
    imagePreviewMaxHeight: 256,
    maxFiles: 10,
    iniFiles: undefined,
}

export default ImageUpload;
