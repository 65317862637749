
import React from 'react';
import {ProtectedRoutes} from '../../components/ProtectedRoute';
import { RoleIds } from '../../models';

import Clients from './Clients';
import Client from './Client';
import Builders from './Builders';
import Builder from './Builder';
import Project from './Project';
import Staff from './Staff';

const access = {access: [RoleIds.ADMIN]}

const AdminRoutesConfig = [
    { 
        path: '/',
        exact: true,
        main: Clients,
        ...access,
    },
    { 
        path: '/admin/client/:id',
        exact: true,
        main: Client,
        ...access,
    },
    { 
        path: '/admin/builders',
        exact: true,
        main: Builders,
        ...access,
    },
    { 
        path: '/admin/builder/:id',
        exact: true,
        main: Builder,
        ...access,
    },
    { 
        path: '/admin/project/:id',
        exact: true,
        main: Project,
        ...access,
    },
    { 
        path: '/admin/staff',
        exact: false,
        main: Staff,
        ...access,
    },
];


export const AdminRoutes = () => (
    <ProtectedRoutes routes={AdminRoutesConfig}/>
);