import React, { Component } from "react";
import { connect } from 'react-redux';
import { Steps, Button, Layout } from 'antd';
import { ButtonBack, ButtonNext } from '../components/Buttons';
import FormQuiz from '../containers/FormQuiz';
import { ProjectActions as actions } from '../actions';
import { calculateShow } from '../helpers';
const { Content, Footer, Header } = Layout;
const Step = Steps.Step;

const initConfig = (quizModel, quizData) => {
    let group = -1;
    const steps = quizModel
        .filter(item => {
            if (!item.show)
                return true;
            const { field } = item.show;
            return calculateShow(item.show, quizData[field]);
        })
        .map(step => {
            const label = step.displayName;
            if (label) {
                group++;
            }
            return {
                ...step,
                label: label,
                group: group,
            }
        });
    const sections = steps
        .filter(item => item.label);
    return {
        steps: steps,
        sections: sections
    }
}

const QuizHeader = (props) => {
    const { steps, currentStep } = props;
    const label = steps[currentStep].label;
    if (label && currentStep > 0 && currentStep < steps.length - 1) {
        return (
            <Header>
                <h2>{label}</h2>
            </Header>
        )
    }
    return null;
}

const QuizNavigation = (props) => {
    const { steps, currentStep, buttons } = props;
    const step = currentStep;
    const last = steps.length - 1;

    switch (step) {
        case 0:
            return [
                buttons.pageBack,
                buttons.start,
            ];
        case (last === -1):
            return [
                buttons.stepBack,
                buttons.done
            ];
        case last:
            return [
                buttons.retake,
                buttons.pageNext
            ];

        default:
            return [
                buttons.stepBack,
                buttons.stepNext,
            ];
    }
}

class ProjectStyle extends Component {
    unmounted = false;
    state = {
        config: { steps: [], section: [] },
        currentStep: -1,
        quizModel: undefined,
    };

    componentDidMount() {
        this.getQuizStyleConfig()
            .then(quizModel => this.init(quizModel, this.props.quizData));
    }

    componentWillReceiveProps(nextProps) {
        const { quizModel } = this.state;
        const { quizData, currentStep, sections } = nextProps;
        const sectionsAreChanged = !Object.equals(sections, this.props.sections);
        const stepIsChanged = currentStep !== this.props.currentStep || currentStep === -1;
        
        if (sectionsAreChanged || stepIsChanged) {
            this.init(quizModel, quizData);
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
        const { config, currentStep } = this.state;
        const { params } = this.props;
        const { steps } = config;
        if (currentStep === steps.length - 1) {
            this.props.markDone(params.id, this.props.path);
        }
    }

    init = (quizModel, quizData) => {
        if (!Object.isDefined(quizModel))
            return;
        const config = initConfig(quizModel, quizData);
        this.setState({
            currentStep: quizData.step || 0,
            config: config,
        });
    }

    getQuizStyleConfig() {
        return actions.getQuizConfig('quiz-style')
            .then(
                quizModel => {
                    if (!this.unmounted)
                        this.setState({ quizModel });
                    return quizModel;
                }
            )
    }

    goto = (step) => {
        const { params } = this.props;
        this.props.setQuizAnswers(params.id, { step });
    }

    back = () => {
        this.goto(this.state.currentStep - 1);
    }

    next = () => {
        const newStep = this.state.currentStep + 1;
        this.goto(newStep);
        window.scrollTo(0, 0);
    }

    clear = () => {
        const values = {};
        // DONT SAVE DATA ON RETAKE
        // const { quizData } = this.props;
        // Object.keys(quizData).forEach(item => values[item] = null);
        this.props.retakeQuiz(this.props.projectId, values, this.props.path)
            .then(data => this.goto(1));
    }

    Buttons = {
        start: (<Button key="1" onClick={this.next} type="primary right">Start</Button>),
        stepBack: (<Button key="2" onClick={this.back}>Back</Button>),
        stepNext: (<Button key="3" htmlType="submit" type="primary right">Next</Button>),
        done: (<Button key="4" htmlType="submit" type="primary right">Done</Button>),
        retake: (<Button key="5" onClick={this.clear} type="dashed" >Edit answers</Button>),
        skipQuiz: (<ButtonNext key="6" type="danger right">Skip for now</ButtonNext>),
        pageNext: (<ButtonNext key="7" type="danger right">Done</ButtonNext>),
        pageBack: (<ButtonBack key="8" type="default left" />),
    }

    nextGroup = () => {
        for (let i = this.state.currentStep + 1; i < this.state.steps.length; i++) {
            if (this.state.steps[i].label) {
                return this.goto(i);
            }
        }
    }

    render() {
        const { config } = this.state;
        let { currentStep } = this.state;
        const { projectId, disabled, quizData } = this.props;

        const { steps, sections } = config;
        if (steps.length === 0 || currentStep < 0) return null;
        if (!steps[currentStep]) currentStep = 0;
        const currentGroup = steps[currentStep].group -1;

        return (
            <Layout className="getting-started-quiz-style">
                <Content className={`quiz-step quiz-step-${currentStep}`}>
                    {/* <CardHelp name="projectStyle" /> */}
                    {currentStep > 0 && (
                        <Steps
                            current={currentGroup}
                            labelPlacement="vertical"
                        >
                            {
                                sections
                                    .map((item, index) => {
                                        if(item.name === "Start") return null;
                                        return ( <Step key={index} title={item.label} /> )})
                            }
                        </Steps>
                    )}
                    <QuizHeader
                        steps={steps}
                        currentStep={currentStep} />
                    <FormQuiz
                        disabled={disabled}
                        id={projectId}
                        onSubmit={this.next}
                        initialValues={quizData}
                        quizName='quiz-style' 
                        model={steps[currentStep].fields} >
                        <Footer>
                            <QuizNavigation
                                steps={steps}
                                currentStep={currentStep}
                                buttons={this.Buttons} />
                        </Footer>
                    </FormQuiz>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = ({ project, router }) => {
    return {
        projectId: project.id,
        disabled: project.disabled,
        quizData: project['quiz-style'],
        path: router.location.pathname,
        sections: project['quiz-style'].sections,
        currentStep: project['quiz-style'].step,
    }
};

const mapDispatchToProps = dispatch => (
    {
        setQuizAnswers: (id, data) => dispatch(
            actions.setQuizAnswers(id, 'quiz-style', data)
        ),
        retakeQuiz: (id, data, path) => dispatch(
            actions.setQuizAnswers(id, 'quiz-style', data)
        ),
        markDone: (id, path) => {
            dispatch(actions.setProgress(id, { [path]: true }));
        }
    }
);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectStyle);
