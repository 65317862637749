import api from './api';
// import { toQueryParams } from '../helpers';

const get = id => {
    if(!id)
        return api.get()
        .then(data => data.data);

    return api.get(`/builders/${id}`);
}
const list = () => {
    return api.get( `/builders`)
    .then(data => data.data);
}

const create = (data) => {
    return api.post('/builders', data);
}

const getImage = (url) => {
    return api.image(url);
}

const update = (id, data) => {
    return api.put(`/builders/${id}`, data);
}

const remove = (id) => {
    return api.delete(`/builders/${id}`);
}

const getLogo = (name, id) => {
    return api.get(`/media/all/${name}/${id}`);
}


export const BuilderService = {
    list,
    remove,
    get,
    create,
    update,
    getLogo,
    getImage
};