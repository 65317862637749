import * as React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { UserActions as actions} from '../actions';
import Form, { FormItem } from '../components/Form';
import {UserModel as model} from '../models';
  
class FormPasswordChangeRequest extends React.Component {

    handleSubmit = (values) => {
        return actions.passwordChangeRequest(values.email);
    }
    
    render() {
        const { loggingIn} = this.props;
        
        return (
            <Form id="frmPasswordChangeRequest"
                initialValues={{}} 
                hideRequiredMark={true}
                onSubmit={this.handleSubmit}>
                <FormItem {...model.email}/>
                <FormItem>
                    <Button 
                        block
                        size="large" 
                        loading={loggingIn}
                        type="default" 
                        htmlType="submit"
                    >Send</Button>
                </FormItem>
            </Form>
        )
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn,
    };
}
  
export default connect(mapStateToProps)(FormPasswordChangeRequest);