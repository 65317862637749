import './Slider.scss';
import React from "react";
import { Slider } from 'antd';


class Slider0To100 extends React.Component {
    state ={ value: []}

    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
            return { value: (nextProps.value) };
        }
        return null;
    }

    triggerChange = (value) => {
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(value);
        }
    }

    render() {
        const { options, props } = this.props;
        const { value } = this.state;
        
        const marks = {};
        options.forEach(item => {
            marks[item.value] = item.text;
        });  

        return (
            <Slider 
                marks={marks} 
                min={0} 
                max={100} 
                value={value || 0} 
                onChange={this.triggerChange}
                {...props}/>
            );
    }
}

export { Slider0To100 };