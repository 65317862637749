import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { authentication } from './authentication.reducer';
import { project } from './project.reducer';

export default (history) => combineReducers({
    authentication,
    project,
    router: connectRouter(history),
});

export * from './routing.middleware';

export const transformSettings = (settings, initialValue = {}) => {
    const res = { ...initialValue };
    settings.forEach( item => {
        if(!res[item.questionType])
            res[item.questionType] = {};
        res[item.questionType][item.questionKey] = item.answer;
    })
    return res;
}