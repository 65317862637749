import '../../assets/sider.scss';

import React from "react";
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';


class AdminSider extends React.Component {
    index = {};
    state = { openKeys: []}
    
    getSelectedKey = (path) => path.split('/').slice(0, 3).join('/');
    
    render () {
        const { path } = this.props;
        const selectedKey = this.getSelectedKey(path)
        return (
            <Menu 
                className="menu" 
                mode="inline"
                selectedKeys={[selectedKey]}>
                <Menu.Item key="/"><Link to="/">Clients</Link></Menu.Item>
                <Menu.Item key="/admin/builders"><Link to="/admin/builders">Builders</Link></Menu.Item>
                <Menu.Item key="/admin/staff"><Link to="/admin/staff">Staff</Link></Menu.Item>
            </Menu>
        )
    }
}


const mapStateToProps = ({ router }) => {
    return { 
        path: router.location.pathname,
    };
}

export default connect(mapStateToProps)(AdminSider);