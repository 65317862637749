import React from 'react';
import PropTypes from 'prop-types';
import {Component} from 'react'
import { Form as RawForm } from 'antd';
import { FieldTypes, FieldRules, FieldRuleTypes, getField } from './config';
import FormContext from './FormContext';
import { calculateShow } from './../../helpers';

const RawFormItem = RawForm.Item;

const initValidation = (validation, currentValues) => {
    const res = [];

    const ruleToFn = (item) => ({
        validator: ((rule, value, callback) => {
            const values = currentValues()
            item(rule, value, callback, values)
        })
    });

    if(!validation)
        return res;

    if(validation.required)
        res.push(FieldRules[FieldRuleTypes.REQUIRED])
    
    const rule = validation.rule;
    if(!rule)
        return res;

    if(typeof rule === 'string'){
        res.push(FieldRules[rule])
    }
    else if(Object.isFunction(rule)){
        res.push(ruleToFn(rule))
    }
    else if (Array.isArray(rule)) {
        rule.forEach(item => {
            res.push(
                (typeof item === 'function') ?
                    ruleToFn(item)
                    : FieldRules[item]
            );
        });
    }
    return res;
}

export class FormItem extends Component {
    state = {
        rules: undefined,
    }

    static contextType = FormContext; 
    
    static propTypes = {
        name: PropTypes.string,
        displayName: PropTypes.any,
        validation: PropTypes.object,
        props: PropTypes.object,
    }

    static defaultProps = {
        validation: {},
        props: {},
    }

    renderChildren = (props) => {
        return getField(
            props.type, 
            {
                options: props.options, 
                props: props,
            })
    }
    
    getDynamicPropValue = ( config ) => {
        const value = this.context.getFieldValue(config.field);
        return calculateShow(config, value);
    }

    render () {
        const { 
            name, displayName, tooltip,
            show, type, children, 
            validation, required 
        } = this.props;
        const { initialValues, getFieldDecorator, currentValues} = this.context;

        if(show &&  !this.getDynamicPropValue(show)){
            return null;
        }
            
        let thisChildren = type ? this.renderChildren(this.props) : children;

        if(!name) {
            return  (
                <RawFormItem 
                    label={displayName} 
                    required={required}
                    extra={tooltip}>
                    {thisChildren}
                </RawFormItem>
            )
        }

        const rules = initValidation(validation, currentValues);

        const properties = {
            rules: rules, 
            valuePropName: 'value',
            displayName: displayName,
            ...this.props.props, 
        } 
        
        if(!!initialValues){
            properties.initialValue = initialValues[name];
        }
        return (
            <RawFormItem 
                label={type === FieldTypes.CHECKBOX ? null : displayName} 
                extra={this.props.tooltip}>                
                {
                    getFieldDecorator(name, properties)(thisChildren)
                }
            </RawFormItem>
        );
    }
}