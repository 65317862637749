import React, { Component } from "react"
import { Row, Col } from 'antd';
import Slider from "react-slick";


import slider1 from '../assets/slider/1.jpg';
import slider2 from '../assets/slider/2.jpg';
import slider3 from '../assets/slider/3.jpg';
import slider4 from '../assets/slider/4.jpg';
import slider5 from '../assets/slider/5.jpg';
// import slider6 from '../assets/slider/6.jpg';
import slider7 from '../assets/slider/7.jpg';

import PublicSider from './Home.Public.Sider';


function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style}}
        onClick={onClick}
      />
    );
  }
  
  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style}}
        onClick={onClick}
      />
    );
  }


class Home extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            autoplaySpeed: 7000,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />
        };
        return (
            <Row type="flex" gutter={36}>
                <Col xs={24} lg={6}>
                    <PublicSider />
                </Col>
                <Col xs={18}>
                    <Row>
                        <h1>Meet us</h1>
                        <Col xs={24} lg={12}>
                            <p>Buider Design Center partners with builders to provide complete interior finish packages—products, installation, and service—for homebuyers. We walk you through the design process and guide you in selecting the right finish options for your space. And because we have established relationships with the nation’s leading manufacturers. The Builder Design Center also ensures the best pricing available.</p>
                        </Col>
                        <Col xs={24} lg={12}>
                            <p>Builder Design Center offers finish options for any budget and style, along with an expertly guided selection process. The result assures a great experience for you and a high level of productivity for your builder.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Slider {...settings}>
                            <div>
                                <div style={{
                                    backgroundImage: "url(" + slider1 + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                            </div>
                            <div>
                                <div style={{
                                    backgroundImage: "url(" + slider2 + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                            </div>
                            <div>
                                <div style={{
                                    backgroundImage: "url(" + slider3 + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                            </div>
                            <div>
                                <div style={{
                                    backgroundImage: "url(" + slider4 + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                            </div>
                            <div>
                                <div style={{
                                    backgroundImage: "url(" + slider5 + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                            </div>
                            <div>
                                <div style={{
                                    backgroundImage: "url(" + slider7 + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                            </div>
                        </Slider>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default Home;