import React from "react"
import { Row, Col } from 'antd';

const NotFound = () => {
        
    return (
        <Row type="flex" justify="space-around" align="middle">
            <Col xl={6}>
                <h1>Page Not Found</h1>
            </Col>
        </Row>
    );
}

export default NotFound;