import React, { Component } from "react"
import { Col, Row, Button, notification, Icon } from 'antd';
import { ClientListModel } from '../../models';
import { ClientActions as actions } from '../../actions';
import DataTable from '../../components/DataTable';
import { history } from '../../helpers';
import ModalNewClient from '../../containers/FormSignup';

class ClientsAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
        }

        this.table = React.createRef();
    }
    
    onRowAction = (item) => {
        return history.push(`/admin/client/${item.id}`);
    }


    toggleNewClientModal = (clientCreated) => {
        const { visible } = this.state;
        const { current : { fetch } } = this.table;
        this.setState({
            visible: !visible,
        });

        if(clientCreated === true) {
            notification.success({
                message: 'A new client has been created successfully!',
                duration: 2000000,
                icon: <Icon type="check-circle" theme="filled" style={{ color: "#52B799", fontWeight: "bolder" }} />,
                onClose: () => {
                    notification.destroy();
                    fetch();
                },
                description:
                    'The client will be notified on the provided email and will be required to verify it.',
            });
        }
    }

    render() {
        const { visible } = this.state;
        return (
            <Row type="flex">
                <Col xl={24}>
                    <h1>Clients</h1>
                    <Button
                        type="primary"
                        style={{ margin: '20px 0' }}
                        onClick={this.toggleNewClientModal}
                    >
                       + Add New
                    </Button>
                    <ModalNewClient 
                        toggleModal={this.toggleNewClientModal}
                        visible={visible}
                    />
                    <DataTable
                        ref={this.table}
                        rowKey="id"
                        columns={ClientListModel}
                        fetchAction={actions.listAdvanced}
                        onRowAction={this.onRowAction}
                        defaultPageSize={15}
                        defaultSort={{
                            order: 'descend',
                            columnKey: 'id',
                        }}
                    />

                </Col>
            </Row>
        );
    }
}

export default ClientsAdmin;