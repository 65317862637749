import { UserConstants as constants } from '../constants';
import { LocalData } from '../services';
import { RoleIds } from '../models';


const loggingIn = !!LocalData.token();
const initialState = Object.freeze({ 
    loggedIn: false, 
    user: { roleId: RoleIds.PUBLIC },
    loggingIn,
});

export function authentication(state = initialState, action) {
    switch (action.type) {
        case constants.LOGIN_REQUEST:
            LocalData.email(action.user.remember ? action.user.email : false);
            return {
                loggingIn: true,
                loggedIn: false,
                user: { ...state.user, ...action.user }
            };
        case constants.LOGIN_SUCCESS: {
            LocalData.token(action.user.token);
            return {
                loggingIn: false,
                loggedIn: true,
                user: { ...state.user, ...action.user }
            };
        }
        case constants.LOGIN_FAILURE:
            return {
                ...initialState,
                loggingIn: false,
                loggedIn: false,
                loginError: action.error,
            };
        case constants.PROFILE_CHANGED:
            return {
                loggingIn: false,
                loggedIn: true,
                user: { ...state.user, ...action.user },
            };
        case constants.LOGOUT_SUCCESS:
            LocalData.token(false);
            return {
                ...initialState,
                loggingIn: false,
                loggedIn: false
            };
        default:
            return state;
    }
}