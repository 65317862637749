import React, { Component } from "react"
import { Col, Row, Button, Divider } from 'antd';
import { UserService as service } from '../../services';
import ModalProjectAddNew from './Project.Documents.AddNew';
import FileView from './Project.Documents.View';

class DocumentsAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            user: {},
            userImages: []
        }
    }

    componentDidMount(){
        this.loadUserFiles();
    }

    loadUserFiles = () => {
        const { userId } = this.props;
        service.getUserFiles(userId).then(({ data: userImages }) => {
            this.setState({ userImages })
        })
    }

    toggleModal = (visible) => {
        if(!visible) this.loadUserFiles();
        this.setState({ visible })
    }

    render() {
        const { visible, userImages } = this.state;
        const { userId } = this.props;
        return (
            <div>
                <Row gutter={32}>
                    <Col span={24}>
                        <Button 
                            type="primary" 
                            className="right" 
                            onClick={() => this.toggleModal(true)}>
                                + Add new
                        </Button>
                        <h2 className="left">Uploaded Files:</h2>
                        <ModalProjectAddNew
                            visible={visible}
                            onClose={() => this.toggleModal(false)}
                            userId={userId}/>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={74}>
                    {
                        userImages.map(file => (
                            <Col xl={12} key={file.id}>
                                <FileView 
                                    file={file} 
                                    editable
                                />
                            </Col>
                        ))
                    }

                </Row>
            </div >
        );
    }
}

export default DocumentsAdmin;
