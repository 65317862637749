import React, { Component } from "react"
import { Col, Row,} from 'antd';
import { ClientListModel } from '../../models';
import { UserActions } from '../../actions';
import DataTable from '../../components/DataTable';
import { history } from '../../helpers';

class StaffAdmin extends Component{
    onRowAction = (item) => {
        return history.push(`/admin/client/${item.id}`);
    }
    render () {
        return (
            <Row type="flex">
                <Col xl={24}>
                    <h1>Staff</h1>
                    <DataTable 
                        rowKey="id"
                        columns={ClientListModel}
                        fetchAction={UserActions.listAllStaff}
                        onRowAction={this.onRowAction} 
                        defaultPageSize={15}
                        defaultSort={{
                            order: 'descend',
                            columnKey: 'id',
                        }}
                    />

                </Col>
            </Row>
        );
      }
}

export default StaffAdmin;