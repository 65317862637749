import React, { Component } from "react";
import { PageHeader, Tabs } from 'antd';
import { UserService as service } from '../../services';
import { history } from '../../helpers';

import ClientDetails from './Client.Details';
import ClientProject from './Project';

const { TabPane } = Tabs;

class Client extends Component {
    unmounted = false;
    state = {data: []}
    
    componentDidMount(){
        const id = this.props.params.id;
        if(id){
            service.get(id)
            .then(data => {
                if(!this.unmounted)
                this.setState( { data })
            });
        }
    }
    componentWillUnmount() {
        this.unmounted = true;
    }

    render () {
        const { data } = this.state;

        const projectId = { id: data.projectId }
        return (
            <div>
                <PageHeader 
                    onBack={history.goBack} 
                    title={`${data.firstName || '...'} ${data.lastName || '...'}`}/>
                <Tabs defaultActiveKey="details" >
                    <TabPane tab="PROJECT" key={`admin/project/${data.projectId}`}>
                        <ClientProject params={projectId} />
                    </TabPane>
                    <TabPane tab="DETAILS" key="details">
                        <ClientDetails data={data}/>
                    </TabPane>
                </Tabs>
            </div>           
        );
      };
}

export default Client;