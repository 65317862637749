import React, { Component } from "react"
import { Col, Row, Divider } from 'antd';
import { connect } from 'react-redux';
import { UserService as service } from '../services';
import FileView from './admin/Project.Documents.View';
import './documents.scss';

class ProjectDocuments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: {},
            userImages: []
        }
    }

    componentDidMount(){
        this.loadUserFiles();
    }

    loadUserFiles = () => {
        const { userId } = this.props;
        service.getUserFiles(userId)
            .then(({ data: userImages }) => {
                this.setState({ userImages })
            });
    }

    render() {
        const { userImages, users } = this.state;
        return (
            <div className="documents-client-view">
                <h1>Documents</h1>
                <h2>Uploaded Files:</h2>
                <Divider />
                <Row gutter={74}>
                    {userImages.map(file => (
                        <Col xl={12} key={file.id}>
                            <FileView 
                                file={file} 
                                users={users}
                            />
                        </Col>
                    ))}
                </Row>
            </div >
        );
    }
}

const mapStateToProps = ({ authentication: { user: { id }}}) => ({
    userId: id,
});

export default connect(mapStateToProps)(ProjectDocuments);
