import { message } from 'antd';
export * from './history.helpers';

// Returns a function, that, as long as it continues to be invoked, will not be triggered. 
// The function will be called after it stops being called for N milliseconds.
export function debounce(func, interval) {
    let timeout;
    return function d1(...args) {
        const context = this;
        const later = function d2() {
            timeout = null;
            func.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, interval || 200);
    };
}

export const showResponseError = (error) => {
    if (!error || !error.message) { return; }
    message.error(`${error.source || ''} ${error.message} ${error.description || ''}`, 5);
    return Promise.reject(error);
}; 

export const showResponseMessage = (text, sec) => {
    message.success(text, sec || 5);
};

const currencyFormatter = (value) => {
    const num = parseFloat(value, 10);
    return `$${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatCurrencyRange = (data, name = '') => `${currencyFormatter(data[`low${name}`])} - ${currencyFormatter(data[`high${name}`])}`;

export const calculateShow = (config, valueToCompare) => {
    if(!valueToCompare)
        return false;
    return OperationsFn[config.op].exp(valueToCompare, config.value);
}

const OperationsFn =  {
    '=':                {exp: (a, b) => a === b, label: '='}
    , '<':              {exp: (a, b) => a < b, label: '<'}
    , '>':              {exp: (a, b) => a > b, label: '>'}
    , '<=':             {exp: (a, b) => a <= b, label: '<='}
    , '>=':             {exp: (a, b) => a >= b, label: '>='}
    , '!=':             {exp: (a, b) => a !== b, label: '!='}
    , 'null || >= ' :   {exp: (a, b) => { b = parseInt(b, 10); return (!Object.isDefined(a) || a === '' || a == null || a >= b); }, label: 'null || >='}
    , 'null || = ' :    {exp: (a, b) => (!Object.isDefined(a) || a === '' || a === null || a === b), label: 'null || ='}
    //@TODO check ddm, 'in' :            {exp: (a, b) => { var arr = b.map(function(item){return item.trim()}); return (arr.indexOf(a) > -1); }, label: 'in'}
    , 'in' :            {exp: (a, b) => (a.indexOf(b) > -1 || b.indexOf(a) > -1), label: 'contains'} //@TODODDM - rename operator to "contains"
};

Object.equals = (x, y) => {
    if (x === y) return true;
    if (!(x instanceof Object) || !(y instanceof Object)) return false;
    if (x.constructor !== y.constructor) return false;
    // eslint-disable-next-line no-restricted-syntax
    for (const p in x) {
        if (!x.hasOwnProperty(p)) continue;
        if (!y.hasOwnProperty(p)) return false;
        if (x[p] === y[p]) continue;
        if (typeof (x[p]) !== 'object') return false;
        if (!Object.equals(x[p], y[p])) return false;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const p in y) {
        if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
    }
    return true;
};

Array.equals = (x, y) =>{
    var type = Object.prototype.toString.call(x);
    if (type !== Object.prototype.toString.call(y)) return false;

    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

    var xLen = x.length;
    var yLen = y.length;
    if (xLen !== yLen) return false;
    
    for (var i = 0; i < xLen; i++) {
        if (Object.equals(x[i], y[i]) === false) return false;
    }

    return true;
}

Object.isObject = x => Object.prototype.toString.call(x) === '[object Object]';

Object.isFunction = x => Object.prototype.toString.call(x) === '[object Function]';

Object.isEmpty = x => Object.equals(x, {});

Object.isDefined = x => (typeof x !== 'undefined');

Object.isString = x => Object.prototype.toString.call(x) === '[object String]';

export const toQueryParams = params => Object.keys(params)
    .map((k) => {
        let value = params[k];
        if (Object.isObject(value)) {
            if (Object.isEmpty(value)) { 
                value = '';
            } else value = JSON.stringify(value);
        }
        if (value) {
            return `${k}=${value}`;
        }
        return '';
    })
    .join('&');


export const getHashValue = (key) => {
    const matches = window.location.hash.match(new RegExp(`${key}=([^&]*)`))
    
    return matches ? matches[1] : null
}

export const getQueryStringValue = (key) => {
    return decodeURIComponent(window.location.search.replace(new RegExp('^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1'))
}

export const loadScript =  (d, s, id, jsSrc, cb) => {
        const element = d.getElementsByTagName(s)[0]
        const fjs = element
        let js = element
        js = d.createElement(s)
        js.id = id
        js.src = jsSrc
        if (fjs && fjs.parentNode) {
          fjs.parentNode.insertBefore(js, fjs)
        } else {
          d.head.appendChild(js)
        }
        js.onload = cb
    }