export const UserConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',

    PROFILE_CHANGED: 'USER_PROFILE_CHANGED',
};

export const ProjectConstants = {
    PROJECT_LOADED: 'PROJECT_LOADED',
    PROJECT_UPDATED: 'PROJECT_UPDATED',
    PROJECT_CREATED: 'PROJECT_CREATED',
    PROJECT_SETTINGS_CHANGED: 'PROJECT_SETTINGS_CHANGED',
    PROJECT_SETTINGS_LOADED: 'PROJECT_SETTINGS_LOADED',
    PROJECT_PROGRESS_CHANGED: 'PROJECT_PROGRESS_CHANGED',
    PROJECT_CLEAR: 'PROJECT_CLEAR',
    PROJECT_STATUSES_LOADED: 'PROJECT_STATUSES_LOADED',
};

export const GONEXT = 'GOTO_NEXT';

export const QuizConstants = {
    QUIZ_CHANGED: 'QUIZ_CHANGED',
};
