import './index.scss';
import React, { Component } from "react"
import PropTypes, { object } from 'prop-types'
import memoize from "memoize-one";
import { Table } from 'antd';
import { renderFn, ColumnTypes, FilterPatterns } from './config';
import { ProjectService } from '../../services';
import { getFiltersProps } from './Filter';
import { project } from '../../reducers/project.reducer';
export { ColumnTypes };

const transformSort = (sorter) => {
    if (Object.isEmpty(sorter))
        return;
    const dir = sorter.order === 'descend' ? 'DESC' : 'ASC';
    return `${sorter.columnKey}+${dir}`;
}

class DataTable extends Component {
    static propTypes = {
        rowKey: PropTypes.string.isRequired,
        defaultPageSize: PropTypes.number.isRequired,
        defaultSort: PropTypes.object.isRequired,
        fetchAction: PropTypes.func.isRequired,
        columns: PropTypes.array.isRequired,
        onRowAction: PropTypes.func,
        paginationPosition: PropTypes.string.isRequired,
    }

    static defaultProps = {
        rowKey: 'id',
        defaultPageSize: 15,
        paginationPosition: 'bottom',
        defaultSort: {
            order: 'descend',
            columnKey: 'id',
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            meta: {},
            pagination: {
                defaultPageSize: props.defaultPageSize,
                pageSizeOptions: ['15', '25', '50', '100', '200'],
                showTotal: (total) => `${total} items`,
                showSizeChanger: true,
                hideOnSinglePage: false,
            },
        }
    }

    componentDidMount() {
        const { defaultPageSize, defaultSort } = this.props;
        this.fetch({
            page: 1,
            take: defaultPageSize,
            sort: transformSort(defaultSort),
        })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })
    }

    fetch = (params = {}) => {
        const { page, take, filters, sort } = params;

        this.setState({ loading: true });
        return this.props.fetchAction(page, take, filters, sort)
            .then(res => {
                const pagination = { ...this.state.pagination };
                if (!res)
                    return;
                this.setState({
                    loading: false,
                    data: res.data,
                    meta: res.meta,
                    pagination: {
                        ...pagination,
                        current: page,
                        total: res.meta.total
                    },
                });
            })
    }

    handleTableChange = (pagination, filters, sorter) => {
        let queryFilters = {};
        Object.keys(filters).forEach(key => {
            var value = filters[key];
            if (!Array.isArray(value)) {
                queryFilters[key] = {
                    value: value,
                    pattern: FilterPatterns.LIKE
                };
            }
            else if (value.length) {
                queryFilters[key] = {
                    value: value,
                    pattern: FilterPatterns.IN
                };
            }
        });

        const pager = { ...this.state.pagination };
        this.setState({
            pagination: { ...pager, ...pagination },
        });

        this.fetch({
            take: pagination.pageSize,
            page: pagination.current,
            sort: transformSort(sorter),
            filters: Object.isEmpty(queryFilters) ? null : queryFilters,
        });
    };

    buildColumn = memoize((columns) => {
        return columns.map(item => {
            let filterProps = getFiltersProps(item, this.filterRef);

            const { key, type, sort } = item;
            return {
                ...item,
                ...filterProps,
                dataIndex: key,
                sorter: sort !== false,
                render: item.render || renderFn[type] || null,
            }
        });
    })

    onRow = record => {
        const onRowAction = this.props.onRowAction;
        if (!onRowAction) {
            return {};
        }
        return {
            onClick: () => onRowAction(record)
        };
    }

    render() {
        const { rowKey, columns, paginationPosition } = this.props;
        const { data, pagination, loading } = this.state;
        const columnsWithFilters = this.buildColumn(columns);
        return (
            <Table
                loading={!Array.isArray(data) || loading}
                rowKey={rowKey}
                dataSource={data}
                columns={columnsWithFilters}
                pagination={pagination ? { ...pagination, position: paginationPosition } : null}
                onChange={this.handleTableChange}
                onRow={this.onRow}
                scroll={{ x: 'max-content' }}
                size="middle">
            </Table>
        )
    }
}
export default DataTable;