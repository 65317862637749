import React from 'react';
import moment from 'moment';

import {
    Input, InputNumber,
    Select,
    DatePicker,
    Switch,
} from 'antd';
import {
    RateImages, SortableList,
    CheckboxGroup,
    RadioGroup,
    Slider0To100,
    Checkbox
} from '../fields';

import { DateFormat } from '../../config';

const TextArea = Input.TextArea;

export const FieldTypes = {
    SELECT: 'selectlist',
    TEXTAREA: 'textarea',
    INPUT: 'INPUT',
    DATE: 'DATE',
    EMAIL: 'EMAIL',
    TEXT: 'title',
    NUMBER: 'number',
    CHECKBOX: 'checkbox',
    CHECKBOXGROUP: 'checkboxlist', //@TODODDM => checkboxlist, + itemsize: 0-12, + option.image, +option.disabled
    RADIOGROUP: 'radiobuttonlist', //@TODODDM => radiobuttonlist + itemsize: 1: 12, + options.image
    SWITCH: 'switch',
    //NEW
    SLIDER: 'slider', //@TODODDM options
    RATEIMAGES: 'rateimages', //@TODODDM options
    SORT: 'sort', //@TODODDM array['kitchen, 'livingroom']
    PASSWORD: 'password',
}

export const FieldRuleTypes = {
    REQUIRED: 'REQUIRED',
    PASSWORD: 'PASSWORD',
    EMAIL: 'EMAIL',
    USERNAME: 'USERNAME',
    ZIP: 'ZIP',
    PHONE: 'PHONE',
    URL: 'URL',
    MAXITEMS3: 'MAXITEMS3',
    MINITEMS3: 'MINITEMS3',
    MINITEMS5: 'MINITEMS5',
    MINITEMS6: 'MINITEMS6',
    DATEINPAST: 'DATEINPAST',
    NUMBER: "NUMBER",
}

export const getField = (type, config) => {
    const options = config.options;
    let preProps = {
        ...config.props,
        options: undefined,
        size: undefined,
        displayName: undefined,
        props: undefined,
    };
    const props = {};
    Object.keys(preProps).forEach(key => {
        if (preProps[key] !== undefined) {
            props[key] = preProps[key];
        }
    })

    switch (type) {
        case FieldTypes.CHECKBOXGROUP: {
            return CheckboxGroup(options, props)
        }
        case FieldTypes.CHECKBOX: {
            return <Checkbox />
        }
        case FieldTypes.SELECT: {
            return (
                <Select {...props}>
                    {options.map((item, idx) => (
                        <Select.Option
                            key={idx}
                            value={item.value}>
                            {item.text}
                        </Select.Option>
                    ))}
                </Select>
            )
        }
        case FieldTypes.RADIOGROUP:
            return <RadioGroup {...props} options={options} />
        case FieldTypes.SLIDER:
            return <Slider0To100 options={options} />
        case FieldTypes.TEXTAREA:
            return <TextArea rows={4} />
        case FieldTypes.TEXT:
            return <div className={props.class}>{options}</div>
        case FieldTypes.SORT:
            return <SortableList options={options} />
        case FieldTypes.RATEIMAGES:
            return <RateImages options={options} props={props} />
        case FieldTypes.DATE:
            return <DatePicker format={DateFormat} />
        case FieldTypes.SWITCH:
            return <Switch {...props} />
        case FieldTypes.NUMBER:
            return <InputNumber {...props} />
        case FieldTypes.PASSWORD:
            return <Input type="password" {...props} autoComplete="none"/>
        default:
            return <Input {...props}/>
    }
}

const maxRule = (num, value, callback) => {
    if (Object.isObject(value))
        value = Object.keys(value);

    if (value && value.length && value.length > num) {
        return callback(`Please, select up to ${num} elements.`);
    }
    callback();
}

const minRule = (num, value, callback) => {
    if (Object.isObject(value))
        value = Object.keys(value);

    if (value && value.length && value.length < num) {
        return callback('All fields are required');
    }
    callback();
}

export const FieldRules = {
    [FieldRuleTypes.REQUIRED]: {
        required: true,
        message: 'This field is required.'
    },
    [FieldRuleTypes.PASSWORD]: {
        pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        message: `Password must be at least 8 characters including one uppercase letter, one lowercase letter and 1 number.`
    },
    [FieldRuleTypes.EMAIL]: {
        type: 'email',
        message: 'The input is not valid E-mail.'
    },
    [FieldRuleTypes.NUMBER]: {
        pattern: /^[0-9]*\.{0,1}[0-9]*$/,
        message: 'The input is not valid Number.'
    },
    [FieldRuleTypes.ZIP]: {
        pattern: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
        message: 'The input is not valid ZIP code.'
    },
    [FieldRuleTypes.PHONE]: {
        pattern: /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/,
        message: 'The input is not valid Phone Number.'
    },
    [FieldRuleTypes.URL]: {
        pattern: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        message: 'The input is not valid URL'
    },
    [FieldRuleTypes.USERNAME]: {
        pattern: /^[\w\d_.-]+$/,
        message: 'Username is invalid'
    },
    [FieldRuleTypes.MAXITEMS3]:
        (rule, value, callback) => maxRule(3, value, callback),
    [FieldRuleTypes.MINITEMS3]:
        (rule, value, callback) => minRule(3, value, callback),
    [FieldRuleTypes.MINITEMS5]:
        (rule, value, callback) => minRule(5, value, callback),
    [FieldRuleTypes.MINITEMS6]:
        (rule, value, callback) => minRule(6, value, callback),
    [FieldRuleTypes.DATEINPAST]: (rule, value, callback) => {
        if (value && value >= moment())
            return callback('The Date should be in the past.');
        return callback();
    }
}

