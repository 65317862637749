import './Project.Header.Button.scss';

import React, { Component } from "react";
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import ProjectHeader from './Project.Header';
import './Project.Header.Banner.scss';

class ProjectProgress extends Component {

    render() {
        const { project, current, path } = this.props;

        const visibleQuestionTracker = current + 1 >= 0 && current + 1 < 9;
        return (
            <div className="quiz-about-header">
                <Row className="btn-project-header card" gutter={60}>
                    <Col span={2} className="project-header-progress">
                        <ProjectHeader project={project} current={current} path={path} />
                    </Col>
                    <Col span={22} className="project-header-text">
                        <div>
                            <h1>Let's Learn About You</h1>
                            {
                                visibleQuestionTracker &&
                                <span>Question {current + 1}/8:</span>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = ({ project }) => {
    return {
        project: project,
    }
}

export default connect(mapStateToProps)(ProjectProgress);