import React, { Component } from "react";
import { PageHeader, Tabs, Col, Row, Button } from 'antd';
import ImageUpload from '../../components/ImageUpload';
import { history } from '../../helpers';
import { BuilderActions as actions } from '../../actions';
import { BuilderModel as model } from '../../models';
import Form, { FormItem } from '../../components/Form';
import { ButtonDelete } from '../../components/Buttons';

const { TabPane } = Tabs;

class Builder extends Component {
    unmounted = false;
    state = { data: {}, logo: [] }

    componentDidMount() {
        const id = this.props.params.id;
        this.getCurrentLogo(id)
    }

    getCurrentLogo=(id)=>{
        if (id) {
            actions.get(id)
                .then(data => {
                    if (!this.unmounted)
                        this.setState({ data })
                });
            actions.getLogo('builder', id)
                .then(({ data: logo }) => {
                    this.setState({ logo })
                })
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    handleSubmit = (values) => {
        const id = this.props.params.id;
        const { data } = this.state;
        if (values.number === data.number) delete values.number;
        this.getCurrentLogo(id)
        return actions.update(data.id, values).then(data => this.setState({ data }));
    }

    handleDelete = () => {
        const { data } = this.state;
        return actions.remove(data.id)
            .then(() => history.goBack());
    }

    render() {
        const { params } = this.props;
        const { data, logo } = this.state;
        const gutter = 32;
        const postPath = data.id ? `logo/builder/${params.id}` : '';
        const avatarLabel = 'Drag & Drop <br/>or <span class="filepond--label-action">Browse</span><br/> profile picture';

        return (
            <div>
                <PageHeader
                    onBack={history.goBack}
                    title={`${data.name || '...'}`} />

                <Tabs defaultActiveKey="details">
                    <TabPane tab="DETAILS" key="details">
                        <Form
                            initialValues={data}
                            onSubmit={this.handleSubmit}>

                            <Row gutter={gutter}>
                                <Col lg={5}>
                                    <h2>Logo</h2>
                                    <Col lg={24}>
                                        <ImageUpload
                                            allowMultiple={false}
                                            postPath={postPath}
                                            iniFiles={logo}
                                            labelIdle={avatarLabel}
                                            imagePreviewHeight={170}
                                            imageCropAspectRatio="1:1"
                                            imageResizeTargetWidth={200}
                                            imageResizeTargetHeight={200}
                                            stylePanelLayout="compact circle"
                                            styleLoadIndicatorPosition="center bottom"
                                            styleButtonRemoveItemPosition="center bottom"
                                        />
                                    </Col>
                                </Col>
                                <Col lg={19}>
                                    <h2>Builder Details</h2>
                                    <Col lg={6}>
                                        <FormItem {...model.number} />
                                    </Col>
                                    <Col lg={18}>
                                        <FormItem {...model.name} />
                                    </Col>
                                    <Col lg={12}>
                                        <FormItem {...model.phone} />
                                    </Col>
                                    <Col lg={12}>
                                        <FormItem {...model.email} />
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <ButtonDelete
                                        onDelete={this.handleDelete}
                                        itemName="this builder" />
                                    <Button
                                        type="primary right-bottom"
                                        htmlType="submit"
                                    >Save</Button>
                                </Col>
                            </Row>
                        </Form >
                    </TabPane>
                </Tabs>
            </div>
        );
    };
}

export default Builder;