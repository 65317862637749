import { FieldTypes, FieldRuleTypes } from '../components/Form/config';
import { StateOptions } from '../config';

//Hardcoded to not check all code;
const isProjectDisabled = () => false;

const ProjectModel = {
    name:{
        name: 'name',
        displayName: 'Name',
        validation: { required: true },
        type: FieldTypes.INPUT, 
        size: 24,
   },
   statusId:{
        name: 'statusId',
        displayName: 'Status',
        validation: { required: true },
        type: FieldTypes.SELECT,
        size: 8,
    },
    coordinatorId:{
        name: 'coordinatorId',
        displayName: 'Responsible Coordinator',
        type: FieldTypes.SELECT,
        allowClear: true,
        size: 8,
    },
    designerId:{
        name: 'designerId',
        displayName: 'Responsible Designer',
        type: FieldTypes.SELECT,
        allowClear: true,
        size: 8,
    },
    homeTypeId:{
        name: 'homeTypeId',
        displayName: 'Home Type',
        validation: { required: true },
        size: 16,
   },
    yearBuilt: {
        name: 'yearBuilt',
        displayName: 'Year Built',
        validation: { required: true },
        size: 24,
    },
    squareFootage: {
        name: 'squareFootage',
        displayName: 'square footage',
        validation: { required: true, rule:FieldRuleTypes.NUMBER },
        size: 24,
    },
    homeRemodelingReasonId: {
        name: 'homeRemodelingReasonId',
        displayName: 'why are you remodeling?',
        size: 24,
    },   
    sameAddress: {
        name: 'sameAddress',
        displayName: 'Same as personal address',
        size: 12,
    },
    address1: {
        name: 'address1',
        displayName: 'Address Line 1',
        validation: { required: true },
        type: FieldTypes.INPUT, 
        size: 12,
    },
    address2: {
        name: 'address2',
        displayName: 'Address Line 2',
        type: FieldTypes.INPUT, 
        size: 12,
    },
    city: {
        name: 'city',
        displayName: 'City',
        validation: { required: true },
        type: FieldTypes.INPUT, 
        size: 8,
    },
    state: {
        name: 'state',
        displayName: 'State',
        validation: { required: true },
        size: 8,
        type: FieldTypes.SELECT,
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
        filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0,
        options: StateOptions,
    },
    zip: {
        name: 'zip',
        displayName: 'Zip',
        validation: { required: true, rule: FieldRuleTypes.ZIP},
        type: FieldTypes.INPUT,        
        size: 8,
    },
    homePetId:{
        name: 'homePetId',
    },
    adults: {
        name: 'adults',
        displayName: 'Adults',
        validation: { required: false },
    },
    kids: {
        name: 'kids',
        displayName: 'Kids',
    },
    expectingMoreKids: {
        name: 'expectingMoreKids',
        props: { valuePropName: 'checked'}
    },
    expectingMorePets: {
        name: 'expectingMorePets',
        props: { valuePropName: 'checked'}
    },
    expectingMoreAgingFamilyMembers: {
        name: 'expectingMoreAgingFamilyMembers',
        props: { valuePropName: 'checked'}
    },
    url: {
        name: 'url',
        displayName: 'Pinterest Board URL',
        // tooltip: "Tip: We suggest creating a new Pinterest board titled “Dwellify” and selecting your favorite 5-10 images so your designer can see your top inspiration.",
        validation: { rule: FieldRuleTypes.URL },
        type: FieldTypes.INPUT,
    },
    comments: {
        name: 'comments',
        displayName: 'Comments',
        type: FieldTypes.TEXTAREA,
    }
};

export const PagesPerProject = [
    {
        url: '/intro',
        title: 'Video Intro',
        time: 2,
    },
    {
        url: '/about-you',
        title: 'Let`s learn about you',
        time: 3,
    },
    {
        url: '/style',
        title: 'What`s your style?',
        time: 10,
    },
    {
        url: '/inspiration',
        title: 'Upload inspiration files',
        time: 5,
    }
]

const isProjectReadyForEstimate = (project) => {
    const isReady1 = isDoneProject(project, '');
    const isReady2 = isDoneProject(project, '/inspiration');
    return isReady1 && isReady2;
}

const isDoneProject = (project, link) => {
    const { progress } = project;
    if(!Object.isDefined(link))
        return project.progressNew >= 100;
    
    const linkToCkeck = `/project/${project.id}${link}`;
    return progress && !!progress[linkToCkeck];
};

export {
    ProjectModel,
    isProjectDisabled,
}

export const ProjectReadyState = {
    isProjectReadyForEstimate,
    isDoneProject,
}