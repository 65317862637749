import React, { Component } from 'react';
import { connect } from 'react-redux';
import Visualizer from './Visualizer';

class VisualWorx extends Component {
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <div className="visualworx-container">
                <h1>VisualWorx</h1>
                <Visualizer user={this.props.user} role="user"/>
            </div>          
        );
    }
}


const mapStateToProps = ({ authentication }) => {
    return { 
        user: authentication.user
    }
};



export default connect(mapStateToProps)(VisualWorx);
