import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Row, Col, Button
} from 'antd';
import { UserActions as actions, BuilderActions } from '../actions';
import { history } from '../helpers';
import Form, { FormItem } from '../components/Form';
import { UserModel as model } from '../models';
import './profile.scss';

class Profile extends Component {
    state = { data: {}, avatar: undefined, builderData: [] };

    componentDidMount(){
        return BuilderActions.list().then(builderData => this.setState({ builderData }))
    }

    handleSubmit = values => {
        const { updateLoggedUser, user } = this.props;
        return updateLoggedUser(values)
        .then(_ => {
            if(user.roleId !== 1) history.push(`/project/${user.projectId}`);
        })
    }

    cancel = () => {
        history.push('/');
    }

    render() {
        const { user } = this.props;
        const { builderData } = this.state;
        const builderOptions = [];
        builderData.forEach(e => {
            builderOptions.push({ text: e.name, value: e.id })
        });
        const gutter = 32;
        const firstTimeLogin = !user.firstName;

        const context = firstTimeLogin ? {
            title: 'Let\'s start by getting to know you...',
            btnSaveLabel: 'Save and Continue',
            cancelBtn: null
        } : {
                title: 'Your Profile',
                btnSaveLabel: 'Save and Exit',
                cancelBtn: (<Button
                    block
                    type="danger"
                    onClick={this.cancel}
                >
                    Cancel

                        </Button>)
            };

        let isDisabled = true;
        if (user.roleId === 1) isDisabled = false;

        return (
            <Form
                initialValues={user}
                onSubmit={this.handleSubmit}
                submitOnEnter={false}
            >
                <Row className="profile-container" justify="center" type="flex">
                    <Col xl={12}>
                        <Row gutter={gutter}>
                        <h1>{context.title}</h1>
                            <Col lg={model.builder.size}>
                                <FormItem {...model.builder} options={builderOptions} disabled={isDisabled} />
                            </Col>
                            <Col lg={model.subdivision.size}>
                                <FormItem {...model.subdivision} disabled={isDisabled} />
                            </Col>
                            <Col lg={model.lotNumber.size}>
                                <FormItem {...model.lotNumber} disabled={isDisabled} />
                            </Col>
                            <Col lg={model.homePlanName.size}>
                                <FormItem {...model.homePlanName} disabled={isDisabled} />
                            </Col>
                        </Row>

                        <Row gutter={gutter}>
                            <h2>Owner 1</h2>
                            <Col lg={24}>
                                <Row gutter={gutter}>
                                    <Col lg={model.firstName.size}>
                                        <FormItem {...model.firstName} />
                                    </Col>
                                    <Col lg={model.lastName.size}>
                                        <FormItem {...model.lastName} />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col lg={model.phone.size}>
                                        <FormItem {...model.phone} />
                                    </Col>
                                    <Col lg={model.email.size}>
                                        <FormItem {...model.email} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={model.address1.size}>
                                <FormItem {...model.address1} />
                            </Col>
                            <Col lg={model.address2.size}>
                                <FormItem {...model.address2} />
                            </Col>
                            <Col lg={model.city.size}>
                                <FormItem {...model.city} />
                            </Col>
                            <Col lg={model.state.size}>
                                <FormItem {...model.state} />
                            </Col>
                            <Col lg={model.zip.size}>
                                <FormItem {...model.zip} />
                            </Col>
                        </Row>


                        <Row gutter={gutter}>
                            <h2>Owner 2 (optional)</h2>
                            <Col lg={24}>
                                <Row gutter={gutter}>
                                    <Col lg={model.owner2FirstName.size}>
                                        <FormItem {...model.owner2FirstName} />
                                    </Col>
                                    <Col lg={model.owner2LastName.size}>
                                        <FormItem {...model.owner2LastName} />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col lg={model.owner2PhoneNumber.size}>
                                        <FormItem {...model.owner2PhoneNumber} />
                                    </Col>
                                    <Col lg={model.owner2Email.size}>
                                        <FormItem {...model.owner2Email} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row gutter={gutter}>
                            <Col xs={12} lg={8}>
                                {context.cancelBtn}
                            </Col>
                            <Col xs={12} lg={{ span: 8, offset: 8 }}>
                                <Button
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {context.btnSaveLabel}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateLoggedUser: (data) => dispatch(actions.updateLoggedUser(data)),
    updateLoggedUserAvatar: data => dispatch(actions.updateLoggedUserAvatar(data))
});

const mapStateToProps = ({ authentication }) => ({ user: authentication.user });

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
