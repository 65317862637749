import { ProjectConstants as constants, GONEXT } from '../constants';
import { ProjectService  as service } from '../services';
import { showResponseError } from '../helpers';

const LoadedSuccess = project => ({ type: constants.PROJECT_LOADED, project });
const UpdatedSuccess = project => ({ type: constants.PROJECT_UPDATED, project });
const CreatedSuccess = project => ({ type: constants.PROJECT_CREATED, project });
const SettingsChanged = settings => ({ type: constants.PROJECT_SETTINGS_CHANGED, settings });
const SettingsLoaded = settings => ({ type: constants.PROJECT_SETTINGS_LOADED, settings });
const StatusesLoaded = statuses => ({ type: constants.PROJECT_STATUSES_LOADED, statuses })
const ClearCurrent = () => ({ type: constants.PROJECT_CLEAR });
const GotoNext = isBack => ({ type: GONEXT, isBack });

const get = id => dispatch => 
    service.get(id)
    .then(
        data => dispatch(LoadedSuccess(data)),
        error => showResponseError({ ...error, source: '[project.get]' })
    );

const getProjectTypes = () => service.getProjectTypes();

const getRemodelingReasons = () => service.getRemodelingReasons();

const getPetTypes = () => service.getPetTypes();

const getImages = id => service.getImages(id);

const list = (...params) => dispatch =>
        service.list(...params)
        .then( 
            (data) => {
                dispatch(ClearCurrent(data));
                return data;
            },
            error => showResponseError({ ...error, source: '[project.list]' })
        );

const listAdvancedByUser = userId => 
    (page = 1, take = 500, filters = {}, sort = 'id+DESC') => 
    service.listAdvanced(page, take, { ...filters, userId }, sort)
    .then( 
        data => data,
        error => showResponseError({ ...error, source: '[project.list]' })
    );

const listAdvanced = (...params) => service.listAdvanced(...params)
    .then( 
        data => data,
        error => showResponseError({ ...error, source: '[project.list]' })
    );

const remove = id => service.remove(id);

const create = values => dispatch => service.create(values)
        .then(
            (data) => { 
                dispatch(CreatedSuccess(data));
                return data;
            },
            error => showResponseError({ ...error, source: '[project.create]' })
        );

const update = (id, values) => dispatch => service.update(id, values)
        .then(
            data => dispatch(UpdatedSuccess(data)),
            error => showResponseError({ ...error, source: '[project.update]' })
        )
        .catch(error => showResponseError(error));

const getStatuses = () => dispatch => 
    service.getStatuses()
    .then(
        data => dispatch(StatusesLoaded(data)),
        error => showResponseError(error) 
    )
    .catch(error => showResponseError(error));

const getSettings = id => dispatch => 
    service.getSettings(id)
    .then(
        data => dispatch(SettingsLoaded(data)),
        error => showResponseError(error) 
    )
    .catch(error => showResponseError(error));


const setSettings = (id, type, data) => dispatch => 
    service.setSettings(id, type, data)
    .then(
        res => dispatch(SettingsChanged(res)),
        error => showResponseError(error)
    );

const setProgress = (id, data) => dispatch => 
    service.setSettings(id, 'progress', data)
    .then(
        res => dispatch(SettingsChanged(res)),
        error => showResponseError(error)
    );

const setStatus = (projectId, statusId) => dispatch => 
    dispatch(update(projectId, { statusId }));


const setQuizAnswers = (id, name,  data) => setSettings(id, name, data);

const getEstimation = (id, forse) => 
    service.getEstimation(id, forse)
    .then(
        data => data,
        error => showResponseError(error)
    );

const getQuizConfig = (...args) => 
    service.getQuizConfig(...args)
    .then(
        data => data,
        error => showResponseError(error)
    );

const getQuizResult = (...args) => 
    service.getQuizResult(...args)
    .then(
        res => res,
        error => showResponseError(error)
    );

const next = isBack => dispatch => dispatch(GotoNext(isBack));

const getAllData = id => dispatch =>
        dispatch(getStatuses())
        .then(() => dispatch(get(id)))
        .then(() => dispatch(getSettings(id)))
        .catch(error => showResponseError({ ...error, source: '[project.all]' }));

export const ProjectActions = {
    list,
    listAdvanced,
    listAdvancedByUser,
    get,
    getAllData,
    create,
    update,
    remove,
    
    getProjectTypes,
    getRemodelingReasons,
    getPetTypes,
    getImages,
    getSettings,
    setSettings,
    setProgress,
    setStatus,
    getEstimation,
    
    setQuizAnswers,
    getQuizConfig,
    getQuizResult,

    next
};
