/* eslint-disable linebreak-style */
import * as React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { UserActions as actions } from '../actions';

import Form, { FormItem } from '../components/Form';
import { LocalData } from '../services';
import { UserModel as model } from '../models';

class Login extends React.Component {
    componentWillMount() {
        const email = LocalData.email();
        this.setState({ remember: !!email, email });
    }

    handleSubmit = (values) => {
        const { login } = this.props;
        login(values);
    }

    render() {
        const { loggingIn } = this.props;
        const { remember, email } = this.state;

        const initialValues = {
            email,
            remember,
        };

        const thisModel = {
            email: model.email,
            password: model.password(),
        };
        
        return (
          <Form
              id="frmLogin"
              initialValues={initialValues}
              hideRequiredMark
              onSubmit={this.handleSubmit}
              spin={false}
          >
            <FormItem {...thisModel.email} />
            <FormItem {...thisModel.password} />
            <Button
                block
                size="large"
                loading={loggingIn}
                type="default"
                htmlType="submit">
                Login
            </Button>

          </Form>
        );
    }
}

function mapStateToProps(state) {
    const { loggingIn, loginError } = state.authentication;
    return {
        loggingIn,
        loginError,
    };
}

const mapDispatchToProps = dispatch => ({
    clearError: () => dispatch(actions.clear()),
    login: values => dispatch(actions.login(values)),
});

const ConnectedLogin = connect(mapStateToProps, mapDispatchToProps)(Login);
export default ConnectedLogin;
