import React from 'react';
import { ProtectedRoutes } from '../components/ProtectedRoute';
import { RoleIds } from '../models';

import Home from './Home';
import Profile from './Profile';
import PasswordChange from './PasswordChange';
import CreatePassword from '../containers/FormCreatePassword';
import Project from './Project';
import NotFound from './NotFound';
import AccessDenied from './AccessDenied';
import Policy from '../components/Policy';

import ProjectDashboard from './Project.Dashboard';
import ProjectQuizStyle from './Project.QuizStyle';
import ProjectInspiration from './Project.Inspiration';
import ProjectVideo from './Project.Video';
import ProjectQuizAbout from './Project.QuizAbout';
import Visualizer from './Project.VisualWorx';
import ProjectDocuments from './Project.Documents';

import AdminDefault from './admin';

export const RootRoutesConfig = [
    { 
        path: '/',
        exact: true,
        main: Home,
        access: RoleIds.ALL,
    },
    {
        path: '/policy',
        exact: true,
        main: Policy,
        access: RoleIds.ALL,
    },
    { 
        path: '/profile',
        main: Profile,
        access: [RoleIds.CLIENT, RoleIds.ADMIN]
    },
    { 
        path: '/project/:id',
        exact: false,
        main: Project,
        access: [RoleIds.CLIENT],
    },
    { 
        path: '/admin',
        exact: false,
        main: AdminDefault,
        access: [RoleIds.ADMIN],
    },
    { 
        path: '/signupconfirm/:token?',
        main: CreatePassword,
        access: RoleIds.ALL,
    },
    {
        path: '/passwordchange/:token?',
        main: PasswordChange,
        access: RoleIds.ALL,
    },
    {
        path: '/403',
        main: AccessDenied,
        access: RoleIds.ALL,
    },
    {
        path: '*',
        main: NotFound,
        access: RoleIds.ALL,
    }
];

export const ProjectRoutesConfig = [
    { 
        path: '/project/:id/intro',
        exact: true,
        main: ProjectVideo,
        access: [RoleIds.CLIENT],
        back: '/project/:id',
        next: '/project/:id/about-you',
    },
    { 
        path: '/project/:id/about-you',
        exact: true,
        main: ProjectQuizAbout,
        access: [RoleIds.CLIENT],
        back: '/project/:id/intro',
        next: '/project/:id/style',
    },
    { 
        path: '/project/:id/style',
        exact: true,
        main: ProjectQuizStyle,
        access: [RoleIds.CLIENT],
        back: '/project/:id/about-you',
        next: '/project/:id/inspiration',
    },
    { 
        path: '/project/:id/inspiration',
        exact: true,
        main: ProjectInspiration,
        access: [RoleIds.CLIENT],
        back: '/project/:id/style',
        next: '/project/:id/visualworx',
    },
    { 
        path: '/project/:id/visualworx',
        exact: true,
        main: Visualizer,
        access: [RoleIds.CLIENT],
        back: '/project/:id/style',
        next: '/project/:id/documents',
    },    
    { 
        path: '/project/:id/documents',
        exact: true,
        main: ProjectDocuments,
        access: [RoleIds.CLIENT],
        back: '/project/:id/visualworx',
    },
    { 
        path: '/project/:id',
        exact: true,
        main: ProjectDashboard,
        access: [RoleIds.CLIENT],
        next: '/project/:id/intro',
    },
];

export const RootRoutes = () => (
    <ProtectedRoutes routes={RootRoutesConfig} />
);

export const ProjectRoutes = () => (
    <ProtectedRoutes routes={ProjectRoutesConfig} />
);
