import './admin.scss';
import React from 'react';
import { Col, Row, } from 'antd';
import Sider from './Sider';
import { AdminRoutes } from './config';


const AdminDefault = () => (
  <Row type="flex" className="sider-holder">
    <Col xl={4}>
      <Sider />
    </Col>
    <Col xl={20}>
      <AdminRoutes />
    </Col>
  </Row>
);

export default AdminDefault;
