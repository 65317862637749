import './Buttons.scss'
import React from "react";
import { Button, Input } from 'antd';

import { connect } from 'react-redux';
import { ProjectActions as actions } from '../actions';

const _ButtonBack = (props) => (
    <Button 
        type={props.type || 'default'}
        size={props.size}
        style={props.style}
        onClick={() => props.next(true)}>
        {props.children || 'Back'}
    </Button>
)

const _ButtonNext = (props) => (
    <Button 
        type={props.type || 'primary right'}
        size={props.size}
        style={props.style}
        onClick={() => props.next()}>
            {props.children || 'Next'}
        </Button>
);

const mapDispatchToProps  = (dispatch) => {
    return {
        next: (isPrev) => {
            dispatch(actions.next(isPrev));
        },
    };
}

const ButtonBack = connect(null, mapDispatchToProps)(_ButtonBack)
const ButtonNext = connect(null, mapDispatchToProps)(_ButtonNext)

class ButtonDelete extends React.Component {
    state = {value: ''}
    handleDelete = () => {
        this.props.onDelete();
    }

    handleChange = (e) => {
        this.setState({
            value: e.target.value
        })
    }
    render() {
        const { type, size, style, children, itemName } = this.props;
        const { value } = this.state;
        return (    
            <div className="btn-delete">
                <h2>DANGER ZONE</h2>
                <h5>To delete {itemName || 'this item'} please write down the word "Delete"</h5>
                <Input
                    value={value}
                    onChange={this.handleChange}
                />
                <Button 
                    type={type || 'danger'}
                    size={size}
                    style={style}
                    disabled={value.toLowerCase() !== 'delete'}
                    onClick={ this.handleDelete }>
                        {children || 'Delete'}
                </Button>
            </div>
        )
    }
};

export {
    ButtonBack,
    ButtonNext,
    ButtonDelete
}