import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';

const Paths = {
    profile: '/profile',
    home: '/',
    accessDenied: '/403'
};


const hasAccess = (roleId = 0, access) => {
    return access.indexOf(roleId) > -1;
}


const redirect = (path, location) => {
    return <Redirect to={{ 
        pathname: path, 
        state: { from: location } 
    }} />
};

const ProtectedRoute = props => {
    const { 
        user, loggedIn, loggingIn,
        component, access, path, 
        params, computedMatch, location 
    } = props;

    
    const getRoute = () => (
        <Route key={path} render={routeProps => {
            return (
                React.createElement(component, {
                    params: {...computedMatch.params, ...params}
                })
            )
        }}></Route>
    );
    if(loggingIn) {   
        if(hasAccess(user.roleId, access))
            return getRoute();
        return null;
    }
    else if(loggedIn) {
        if(path !== Paths.profile && !user.firstName){
            return redirect(Paths.profile)
        }
        else if(hasAccess(user.roleId, access)){ 
            return getRoute();
        }
        else {
            return redirect(Paths.accessDenied, location)
        }
    }
    else if(hasAccess(user.roleId, access)) {
        return getRoute();
    }
    else {
        return redirect(Paths.home, location);
    }
}

const _ProtectedRoutes = (props) => {
    const { routes, user, loggedIn, loggingIn } = props;
    return (
        <Switch>
            { routes.map((route) => (
                <ProtectedRoute
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    component={route.main}
                    access={route.access}
                    user={user}
                    loggedIn={loggedIn}
                    loggingIn={loggingIn}
                />
            ))}
        </Switch>
    )
}

const mapStateToProps = ( {authentication} ) => {
    return {
        user: authentication.user,
        loggedIn: authentication.loggedIn,
        loggingIn: authentication.loggingIn,
    };
};

const ProtectedRoutes = connect(mapStateToProps)(_ProtectedRoutes);
export { ProtectedRoutes };