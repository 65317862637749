import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import ImageUpload from '../components/ImageUpload';
import { ProjectActions as actions } from '../actions';
import { FormItem, FormWithProgress } from '../components/Form';
import { ProjectModel as model } from '../models';
import { ButtonBack, ButtonNext } from '../components/Buttons';

const { Footer } = Layout;

class ProjectInspiration extends Component {
    unmounted = false;
    state = { 
        images: undefined,   
        overviewVisible: false,
        helpVisible: false,
    };

    componentDidMount() {
        this.loadImages(this.props.params.id);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.params.id !== this.props.params.id) { 
            this.loadImages(nextProps.params.id); 
        }
    }
    
    componentWillUnmount() {
        this.unmounted = true;
    }

    loadImages = (projectId) => {
        if (!projectId) 
            return;

        actions.getImages(projectId)
            .then((images) => {
                if (!this.unmounted)
                this.setState({ images });
            });
    }

    handleValuesChange = (changed, all) => {
        const { update, project } = this.props;
        update(project.id, changed);
    }

    handleImagesUploaded = () => {
        const { params } = this.props;
        this.loadImages(params.id);
    }

    render() {
        const { hideButtons, params, disabled, project } = this.props;
        const { images } = this.state;
        
        return (
            <div>
                <h1>Inspiration</h1>
            {project.id && (
                    <FormWithProgress
                        key={project.id}
                        disabled={disabled}
                        settingsType="inspiration"
                        hideButtons={hideButtons}
                        initialValues={project}
                        onValuesChange={this.handleValuesChange}
                    >
                    <div className="ant-form-item-label">
                        <label>Please upload images of rooms or room details that you like</label>
                    </div>
                    <ImageUpload
                        disabled={disabled}
                        allowMultiple
                        iniFiles={images}
                        maxFiles={20}
                        imagePreviewMaxHeight={250}
                        postPath={`inspiration/project/${params.id}`}
                        onSuccess={this.handleImagesUploaded}
                    />
                    <FormItem {...model.url} />
                    <FormItem {...model.comments} />
                    {!hideButtons
                        && (
                        <Footer>
                            <ButtonBack />
                            <ButtonNext>To Visualizer</ButtonNext>
                        </Footer>
                    )}
                    </FormWithProgress>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ project = {}, router }) => ({
    path: router.location.pathname,
    project
});

const mapDispatchToProps = dispatch => ({
        update: (id, data) => dispatch(
            actions.update(id, data)
        ),
        setProgress: (id, value) => {
            return dispatch(
                actions.setProgress(id, value)
            );
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInspiration);
