import axios from 'axios';
import { token } from '.';
import store from '../store';
import { logoutSuccess } from '../actions/user.actions';
import { ImageUrlDelimiter } from '../config';

const BaseUrl = process.env.REACT_APP_API;// REACT_APP_POSTMAN_API;
const PostmanApiKey = process.env.REACT_APP_POSTMAN_API_KEY;
const AuthPrefix = 'Bearer ';

const api = axios.create({
    baseURL: BaseUrl,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': PostmanApiKey,
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache, no-store'

    },
    transformRequest: [function tr(data, headers) {
        return JSON.stringify(data);
    }],
    timeout: 10000000,
    responseType: 'json',
});

api.download = (url, name) => api({
    method: 'get',
    url: url, // `/media/${file.id}:${file.name}`,
    responseType: 'arraybuffer',
    transformResponse: [(data, headers) => {
        return { 
            blobData: data, 
            contentType: headers['content-type']
        };
    }],
})
.then(({blobData, contentType}) => {
    const blob = new Blob([blobData], {type: contentType});
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were 
        // revoked by closing the blob for which they were created. 
        // These URLs will no longer resolve as the data backing 
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, name);
    }
    else {
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        
        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking 
        // is enabled.
        if (!name) {
            tempLink.setAttribute('target', '_blank');
        }
        else {
            tempLink.setAttribute('download', name); 
        }
        
        document.body.appendChild(tempLink);
        tempLink.click();
        
        // Fixes "webkit blob resource error 1"
        setTimeout(function() {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }, 0)
    }
});

api.image = (data) => {
    const headers = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token()}`
        }
    };
    const image = data[0]
    return new Promise((resolve, reject) => {
        if (!image || !image.id || !image.name) { return resolve(); }

        const url = `${BaseUrl}/media/${image.id}${ImageUrlDelimiter}${image.name}`;
        fetch(url, headers)
            .then(response => response.blob())
            .then(blob => resolve(URL.createObjectURL(blob)))
            .catch(err => reject(err));
    });
    /* @TODONEXT replace fetch with api
    return api({
        method: 'get',
        url: url,
    })
    .then(response => response.blob()) 
    .then(blob => URL.createObjectURL(blob)); */
};

const getCookie = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

api.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `${AuthPrefix}${token()}`;
        config.headers['Custom-Tests-Token'] = getCookie('Custom-Tests-Token');
        return config;
    }, error => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => {
        if (response.headers['content-type'] === 'application/octet-stream') {
            const disposition = response.headers['content-disposition'] || '';
            const filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition)[1];
            return {
                file: response.data,
                filename
            };
        }
        const authHeader = response.headers.authorization;
        if (authHeader) {
            const authToken = authHeader.substr(AuthPrefix.length);
            token(authToken);
        }
        if (response.data) {
            return response.data;
        }
        return response;
    },
    (error) => {
        const response = error.response || {};
        const responseError = response.data;
        const code = response.status;

        if (code === 401) {
            // The token is invalid
            store.dispatch(logoutSuccess());
            return Promise.reject({ code });
        }
        if (code === 403) {
            // The token has expired
            store.dispatch(logoutSuccess());
            return Promise.reject({ code, message: responseError.error });
        }
        const err = { code };
        if (error.message) {
            err.message = error.message;
            err.description = error.description || '';
        }

        if (responseError) {
            if (responseError.messages) {
                err.message = Object.values(responseError.messages).join(' ');
            } else {
                err.message = responseError.error;
            }
        }

        if (!err.message) {
            err.message = JSON.stringify(error);
        }

        return Promise.reject(err);
    }
);

export default api;
