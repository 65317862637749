import React, { Component } from "react"
import { Col, Row, Button, Modal, Table } from 'antd';
import { BuilderActions as actions } from '../../actions';
import { BuilderListModel } from '../../models';
import { history } from '../../helpers';
import FormCreateBuilder from '../../containers/FormCreateBuilder';

class BuildersAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            data: [],
        }
        
        this.toggleModal = this.toggleModal.bind(this)
    }

    toggleModal = (refresh) => {
        const { visible } = this.state;
        if(refresh) this.getData()
        this.setState({
            visible: !visible,
        });
    }

    componentDidMount(){
        this.getData()
    }

    getData = () => {
        return actions.list().then(data=> this.setState({ data }))
    }

    onRowAction = (item) => {
        return { 
            onClick: () => history.push(`/admin/builder/${item.id}`)
        }
    }

    render() {
        const { visible, data } = this.state;
        return (
            <Row type="flex">
                <Col xl={24}>
                    <h1>Builders</h1>
                    <Button
                        type="primary"
                        style={{ margin: '20px 0' }}
                        onClick={this.toggleModal}
                    >
                        + Create Builder
                        </Button>
                    <Modal
                        title="Create new builder:"
                        visible={visible}
                        onOk={this.toggleModal}
                        onCancel={this.toggleModal}
                        footer={null}
                    >
                        <FormCreateBuilder toggleModal={this.toggleModal} />
                    </Modal>
                    <Table
                        rowKey="id"
                        dataSource={data}
                        columns={BuilderListModel}
                        pagination={false}
                        onRow={this.onRowAction}
                        defaultSort={{
                            order: 'descend',
                            columnKey: 'id',
                        }} />
                </Col>
            </Row>
        );
    }
}

export default BuildersAdmin;