import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col, Icon, Button, Tooltip } from 'antd';
import { UserService as service } from '../../services';
import { ImageUrlDelimiter } from '../../config';


const docIcons = icon => {
    const options = {
        'image/jpeg': 'file-image',
        'application/pdf': 'file-pdf',
        'image/png': 'file-image',
        'inode/x-empty': 'file-word',
        'text': 'file-text',
    }
   return options[icon] || 'file';
}
const ProjectDocuments = ({ file = {custom_properties: {}}, editable = false }) => {
    const [deleted, setDeleted] = useState(false);
    const fileId = file.id;
    const fileName = file.name;
    const ownerName = file.custom_properties.userName;
    const formattedDate = moment(file.date).format('DD.MM.YYYY');
    const description = file.custom_properties.description ;
    const apiFileName = `${fileId}${ImageUrlDelimiter}${fileName}`;

    const remove = () => {
        service.removeFile(apiFileName)
        .then(() => setDeleted(true))
    }

    const download = () => {
        service.downloadFile(file.url, file.name);
    }

    const preview = () => {
        service.downloadFile(file.previewUrl);
    }

    if(deleted) return null;

    return (
        <Row gutter={32} className="document-view">
            <Col span={5}>
                <div className="icon">
                    <Icon  title={fileName} type={docIcons(file.type)} />
                </div>
            </Col>
            <Col span={19}>
                <h3 title={fileName}>{ fileName }</h3>
                <div className="props">{ formattedDate } | { ownerName }</div>
                <Tooltip placement="topLeft" title={ description } arrowPointAtCenter>
                    <div>{ description }</div>
                </Tooltip>
                <Button 
                    type="link"
                    onClick={download}>
                        <Icon type="download" />
                        DOWNLOAD
                </Button> 
                <Button 
                    type="link"
                    style={{ marginLeft: 15 }}
                    onClick={preview}>
                        <Icon type="file-search" />
                        Open
                </Button> 
                { editable && (
                    <Button 
                        type="link right danger" 
                        onClick={remove}
                    >
                        <Icon type="delete" />
                        DELETE</Button>
                )}
            </Col>
        </Row>
    )
}

export default ProjectDocuments;