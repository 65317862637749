import React, { Component } from "react";
import { history } from '../../helpers';
import { Row, Col, Button } from 'antd';
import Form, { FormItem } from '../../components/Form';
import { UserModel as model } from '../../models';
import { UserService as service } from '../../services';
import { BuilderActions as action } from '../../actions';
import { ButtonDelete } from '../../components/Buttons';


class AdminClientDetails extends Component {
    unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: {},
            builderData: []
        };
    }

    componentDidMount() {
        return action.list().then(builderData => this.setState({ builderData }))
    }

    componentWillUpdate(props) {
        const { data } = props;
        if (!this.state.loaded && data && data.id) {
            if (!this.unmounted)
                this.setState({
                    loaded: true,
                    data: data
                });
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    handleSubmit = (values) => {
        const { data } = this.state;
        return service.update(data.id, values).then(data => this.setState({ data }));
    }

    handleDelete = () => {
        const { data } = this.state;
        return service.remove(data.id)
            .then(() => history.goBack());
    }

    render() {
        const { data, builderData } = this.state;
        const gutter = 32;
        const builderOptions = [];
        builderData.forEach(e => {
            builderOptions.push({ text: e.name, value: e.id })
        });
        return (
            <Form
                initialValues={data}
                onSubmit={this.handleSubmit}>

                <Row gutter={gutter}>
                    <Col lg={24}>
                        <Row>
                            <Col lg={6}>
                                <FormItem {...model.roleId} />
                            </Col>
                        </Row>
                        <h2>Client Details</h2>
                        <Row gutter={gutter}>
                            <Col lg={12}>
                                <FormItem {...model.builder} options={builderOptions} />
                            </Col>
                            <Col lg={12}>
                                <FormItem {...model.subdivision} />
                            </Col>
                            <Col lg={12}>
                                <FormItem {...model.homePlanName} />
                            </Col>
                            <Col lg={12}>
                                <FormItem {...model.lotNumber} />
                            </Col>
                        </Row>

                        <h2>Owner 1</h2>
                        <Row gutter={gutter}>
                            <Col lg={24}>
                                <Row gutter={gutter}>
                                    <Col lg={model.firstName.size}>
                                        <FormItem {...model.firstName} />
                                    </Col>
                                    <Col lg={model.lastName.size}>
                                        <FormItem {...model.lastName} />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col lg={model.phone.size}>
                                        <FormItem {...model.phone} />
                                    </Col>
                                    <Col lg={model.email.size}>
                                        <FormItem {...model.email} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={model.address1.size}>
                                <FormItem {...model.address1} />
                            </Col>
                            <Col lg={model.address2.size}>
                                <FormItem {...model.address2} />
                            </Col>
                            <Col lg={model.city.size}>
                                <FormItem {...model.city} />
                            </Col>
                            <Col lg={model.state.size}>
                                <FormItem {...model.state} />
                            </Col>
                            <Col lg={model.zip.size}>
                                <FormItem {...model.zip} />
                            </Col>
                        </Row>

                        <h2>Owner 2 (optional)</h2>
                        <Row gutter={gutter}>
                            <Col lg={24}>
                                <Row gutter={gutter}>
                                    <Col lg={model.owner2FirstName.size}>
                                        <FormItem {...model.owner2FirstName} />
                                    </Col>
                                    <Col lg={model.owner2LastName.size}>
                                        <FormItem {...model.owner2LastName} />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col lg={model.owner2PhoneNumber.size}>
                                        <FormItem {...model.owner2PhoneNumber} />
                                    </Col>
                                    <Col lg={model.owner2Email.size}>
                                        <FormItem {...model.owner2Email} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>


                <Row>
                    <Col span={24}>
                        <ButtonDelete
                            onDelete={this.handleDelete}
                            itemName="this client" />
                        <Button
                            type="primary right-bottom"
                            htmlType="submit"
                        >Save</Button>
                    </Col>
                </Row>
            </Form >
        );
    };
}

export default AdminClientDetails;