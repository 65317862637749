import React, { Component } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { connect } from 'react-redux';
import { ProjectActions as actions } from '../actions';
import { ButtonNext}  from '../components/Buttons';

class GettingStarted extends Component {
    handleEnded=()=>{
        const { path, markDone } = this.props;
        const { id } = this.props.params;
        markDone(id, path)
    }

    render() {
        const vimeoProps = {
            video: '188906711',
            responsive: true,
            autoplay: false,
            controls: true,
            onProgress: this.handleProgress,
            onEnd: this.handleEnded,
        }

        return (
            <div>
                <h1>What to Expect</h1>
                <Vimeo
                    {...vimeoProps}
                />
                <div className="buttons-back-next">
                    <ButtonNext/>
                </div>
            </div>          
        );
    }
}


const mapStateToProps = ({ router }) => {
    return { 
        path: router.location.pathname,
    }
};

const mapDispatchToProps = dispatch => (
    {
        markDone: (id, path) => dispatch(actions.setProgress(id, { [path]: true })),
        get: (id) => dispatch(actions.getAllData(id)),
        isCompleted: (id) => dispatch(actions.getSettings(id))
    }
);


export default connect(mapStateToProps, mapDispatchToProps)(GettingStarted);
