import Form from './Form';
import { connect } from 'react-redux';
import { ProjectActions as actions} from '../../actions';
import { RoleIds } from '../../models';

class BaseForm extends Form {    
       
    componentWillUnmount(){
        const { trackProgress } = this.props;
        if(!trackProgress)
            return;
        
        const { projectId, progress, disabled } = this.props;
        if(!projectId || disabled)
            return;
        const path = this.props.path;
        const form = this.getForm();
        
        form.validateFields((error) => {
            const newProgress = !error? true: null;
            if(progress[path] !== newProgress){
                this.props.setProgress(projectId,  {[path]: newProgress});              
            }  
        });       
    }
}

const mapStateToProps = ({ router, project, authentication }) => {
    const userIsClient = authentication.user.roleId === RoleIds.CLIENT;
    return { 
        path: router.location.pathname,
        projectId: project.id,
        progress: project.progress || {},
        disabled: project.disabled && userIsClient,
        trackProgress: !project.disabled && userIsClient
    };
}

const mapDispatchToProps  = (dispatch) => {
    return {
        setProgress: (id, value) => {
            return dispatch(
                actions.setProgress(id, value)
            );
        },
    };
}

const FormWithProgress = connect(mapStateToProps, mapDispatchToProps)(BaseForm);
export { FormWithProgress };