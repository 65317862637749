import './CardUser.scss'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {
    Dropdown, Icon, Menu, Avatar
} from 'antd';

import { Link } from 'react-router-dom';
import { UserService as service } from '../services';
class CardUser extends Component {
    state = {}

    static propTypes = {
        user: PropTypes.object,
    }
    static defaultProps = {
        user: null,
    }

    componentDidMount(){
        this.loadAvatar(this.props.user);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.user)
            this.loadAvatar(nextProps.user);
    }

    loadAvatar = (user) => {
        if(user && user.avatar) {
            service.getImage(user.avatar)
            .then(avatarUrl => {
                this.setState({avatarUrl});
            });
        }
        else {
            this.setState({avatarUrl: ''})
        }
    }

    handleLogout = () => {
        return this.props.logout();
    }
    
    menu = (
        <Menu>
            <Menu.Item>
                <Link to="/">Dashboard</Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/profile">Profile</Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" onClick={this.handleLogout}>
                Logout
            </Menu.Item>
        </Menu>
    );
        
   render () {
        const { loggedIn, user } = this.props;
        const { avatarUrl } = this.state;
        
        if(!loggedIn)
            return null;
        
        const userName = (user && user.firstName)?  `${user.firstName} ${user.lastName || ''}` : 'Unknown';

        return (            
            <Dropdown className="card-user" overlay={this.menu}>                
                <button>                   
                    <Link to="/profile">
                        <Avatar size={40} icon="user" src={avatarUrl}/>
                        <span>{userName}</span>
                    </Link>
                    <Icon type="down" />
                </button>            
            </Dropdown>
        )
    }
}

const mapStateToProps = ( {authentication} ) => {
    return {
        user: authentication.user,
        loggedIn: authentication.loggedIn,
    };
};

export default connect(mapStateToProps)(CardUser);