import './dashboard.scss';

import React, { Component } from "react";
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import { Row, Col, Divider } from 'antd';
import { PagesPerProject } from '../models';
import visualWorx from '../assets/visualworx.png';
import { history } from '../helpers';
import { UserService as service } from '../services';
import emptyDocuments from '../assets/empty-documents.png';
import FileView from './admin/Project.Documents.View';

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userImages: []
        };
    }

    componentDidMount() {
        this.loadUserFiles();
    }

    dateSorting(a, b){  
        var dateA = new Date(a.date).getTime();
        var dateB = new Date(b.date).getTime();
        return dateA > dateB ? -1 : 1;
    }; 
    
    loadUserFiles = () => {
        const { userId } = this.props;
        service.getUserFiles(userId)
            .then(({ data: userImages }) => {
                const filterFiles = userImages.sort(this.dateSorting)
                if(filterFiles && filterFiles.length > 0) filterFiles.length = 2;
                this.setState({ userImages: filterFiles })
            });
    }

    goToVisualWorx = () => history.push(`/project/${this.props.params.id}/visualworx`)
    goToDocuments = () => history.push(`/project/${this.props.params.id}/documents`)

    isCompleted = (page, progress) => {
        let pages = progress.filter(cls => page.includes(cls.key));
        if (pages.length > 0) return true;
        return false;
    }

    render() {
        const { project: { progress = {}, statusTitle }, path } = this.props;
        const { userImages } = this.state;
        const documentView = (userImages && userImages.length > 0);
        const displayGoTo = documentView ? 'block' : 'none';
 
        const projectIsCompleted = (page) => {
            if(page)
                return Object.keys(progress).includes(`${path}${page.url}`);
            return PagesPerProject.every(page => progress[`${path}${page.url}`]);
        }

        return (
            <Row className="dashboard">
                <Row className="dashboard-header">
                    <h1>Dashboard</h1>
                    <span>Project status: </span><span>{statusTitle}</span>
                </Row>
                <Row>
                    <Col className="dashboard-progress" span={24}>
                        <Col span={12}>
                            <h3>Our process to help you make your selections…</h3>
                            <p>We start by introducing you to the design center process through a brief introductory video, followed by a short questionnaire. Finally, our VisualWorx tool will enable you to put together and preview products that suit your style.  Our professional designers will review all of this information prior to your design appointment, so that they can create a design experience that is specifically catered to you.</p>
                        </Col>
                        <Col span={12} className="dashboard-progress-tracking">
                            {
                                projectIsCompleted() ?
                                <span>Thank you for completing your profile! <br /> You can now collaborate with designers using Documents and VisualWorx.</span>
                                :
                                <span>Complete your profile! <br />Please review and fill the missing sections:</span>
                            }
                            <div className="dashboard-progress-tracking-pages">
                                {
                                    PagesPerProject.map(page => {
                                        const isCompleted = projectIsCompleted(page);

                                        return (
                                            <Row gutter={12} key={page.url} className={isCompleted ? 'completed' : ''}>
                                                <Col span={2} className="tracking-page-tick">
                                                    <div/>
                                                </Col>
                                                <Col span={15} className="page-name">
                                                    <Link to={`${path}${page.url}`}>{page.title}</Link>
                                                </Col>
                                                <Col span={7}>
                                                    <span  className='progress-text'>{ isCompleted ? 'Completed!' : `${page.time}  min` }</span>
                                                </Col>
                                                <Divider />
                                            </Row>
                                        );
                                    })
                                }
                            </div>
                        </Col>
                    </Col>
                </Row>
                <Row className="dashboard-visualworx-documents">
                    <Col span={12}>
                        <Row className={`dashboard-documents-div documents-${displayGoTo}`} >
                            <Col span={18}><h3>Recent Files</h3></Col>
                            <Col span={6} style={{ display: displayGoTo }}>
                                <span onClick={this.goToDocuments}>View All</span>
                            </Col>
                            {
                                documentView ?
                                    <Row className="documents-available">
                                        {userImages.map(file => (
                                            <Col xl={12} key={file.id}>
                                                <FileView
                                                    file={file}
                                                />
                                            </Col>
                                        ))}
                                    </Row> :
                                    <Row className={`documents-view-${documentView}`}>
                                        <Col span={24}>
                                            <img src={emptyDocuments} width={85} height={85} alt="document-logo" />
                                        </Col>
                                        <Col span={24}>
                                            <span>No files have been uploaded yet...</span>
                                        </Col>
                                    </Row>
                            }
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row className="dashboard-visualworx-div" onClick={this.goToVisualWorx}>
                            <Col span={24}>
                                <img src={visualWorx} width={118} height={118} alt="visualworx-logo" /><br />
                            </Col>
                            <Col span={24}>
                                <p>VisualWorx: Visualize your home</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Row>
        );
    }
}

const mapStateToProps = ({ router, project, authentication: { user: { id } } }) => {
    return {
        path: router.location.pathname,
        project,
        userId: id,
    };
};

export default connect(mapStateToProps)(Dashboard);