import * as React from 'react';
import { Input, Button } from 'antd';
import { UserActions as actions } from '../actions';
import Form, { FormItem } from '../components/Form';
import { history } from '../helpers';
import { UserModel as model } from '../models';
import { connect } from 'react-redux';

class PasswordChange extends React.Component {
    unmounted = false;
    state = {
        confirmDirty: false,
    }

    componentWillMount() {
        localStorage.clear();
    }

    handleSubmit = (values) => {
        const { login } = this.props;
        const user = {
            token: this.props.params.token,
            password: values.password,
            confirmPassword: values.confirmPassword
        }
        return actions.createPassword(user)
            .then((data) => {
                login({ ...user, ...data })
                setTimeout(() => history.push('/profile'), 0)
            });
    }

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }

    validatePasswordConfirm = (rule, value, callback) => {
        if (value && value !== this.form.getFieldValue('password')) {
            callback(`Password doesn't match`);
        } else {
            callback();
        }
    }

    validatePassword = (rule, value, callback) => {
        if (value && this.state.confirmDirty) {
            this.form.validateFields(['confirm'])
        }
        callback();
    }

    render() {
        const thisModel = {
            password: model.password(this.validatePassword),
            confirm: model.confirm(this.validatePasswordConfirm)
        }

        return (
            <div className="form-create-password" >
                <Form
                    initialValues={{}}
                    wrappedComponentRef={(inst) => this.form = inst}
                    onSubmit={this.handleSubmit}>
                    <h3>New account setup:</h3>
                    <div className="form-create-password-description">
                        <span>Please enter the password that you would like to use for logging into your account. Your password should contain at least 8 symbols, one digit and one special character</span>
                    </div>

                    <FormItem {...thisModel.password}>
                        <Input type="password"
                            placeholder="Password"
                            autoComplete="none" />
                    </FormItem>

                    <FormItem {...thisModel.confirm}>
                        <Input type="password"
                            placeholder="Password"
                            autoComplete="none"
                            onBlur={this.handleConfirmBlur} />
                    </FormItem>

                    <Button
                        type="primary"
                        size="large"
                        style={{ width: '100%' }}
                        htmlType="submit">Create password</Button>
                </Form>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signUpTokenValidate: (token) => dispatch(actions.signUpTokenValidate(token)),
        logout: () => dispatch(actions.logout()),
        login: values => dispatch(actions.login(values)),
    };
}

function mapStateToProps(state) {
    const { loggedIn } = state.authentication;
    return { loggedIn };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChange);
