import React, { Component } from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import { UserService as service } from '../services';
import './visualworx.scss';

class Visualizer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            visible: false,
        }
    }

    toggleModal = (toggle) => {
        this.setState({ visible: toggle })
    }

    componentDidMount() {
        const { role } = this.props;
        if(role === 'admin') service.get(this.props.userId).then(user => this.setState({ user }));
        if(role === 'user') this.setState({ user: this.props.user })
    }

    render() {
        const { user, visible } = this.state;
        return (
            <div>
                <p>For optimal viewing experience you <Button type="link" onClick={() => this.toggleModal(true)}>expand the view</Button> or <Button type="link" target="_blank" href={`https://visualworx.builderdesigncenter.com/?builderCode=${user.builderNumber}&userId=${user.id}`}> open in new window</Button></p>
                <iframe 
                    title="VisualWorx"
                    src={`https://visualworx.builderdesigncenter.com/?builderCode=${user.builderNumber}&userId=${user.id}`}
                    scrolling="no"
                    height="600px"
                    width="100%"
                    style={{ overflow: "hidden" }}
                    frameBorder="0"
                    seamless="seamless"></iframe>

                <Modal
                    visible={visible}
                    className="visualworx-modal"
                    onOk={() => this.toggleModal(false)}
                    onCancel={() => this.toggleModal(false)}
                    footer={null}
                ><iframe 
                    title="VisualWorx Modal"
                    src={`https://visualworx.builderdesigncenter.com/?builderCode=${user.builderNumber}&userId=${user.id}`}
                    scrolling="no"
                    height="100% !important"
                    width="100% !important"
                    style={{ overflow: "hidden" }}
                    frameBorder="0"
                    seamless="seamless"></iframe>
                </Modal>
            </div>
        );
    }
}

PropTypes.propTypes = {
    userId: PropTypes.number.isRequired
}

export default Visualizer;
