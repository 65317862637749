import api from '../services/api';
import moment from 'moment';
import {RoleIds} from '../models';

const transformIn = data => {
    // @TODO ID
    return { 
        ...data, 
        birthDate: data.birthDate ? moment.utc(data.birthDate) : null,
        newsletters: !!data.newsletters,
    }  
}

const transformOut = data => {
    if('newsletters' in data)
        data.newsletters = data.newsletters? 1: 0;
    if('birthDate' in data && data.birthDate)
        data.birthDate = data.birthDate.format('MM/DD/YYYY')
    return data;
}

const login = (credentials) => {
    return api.post('/token', credentials)
    .then(data => transformIn(data))
    .catch( error => {
        if (error.code === 401) {
            return Promise.reject({message: "Invalid E-mail or Password"});
        }
        return Promise.reject(error);
    })
}

const socialAuthTokenValidate = (metaUser) => {
    const user = metaUser._profile;
    const provider = metaUser._provider;
    const token = metaUser._token;

    const data = { 
        userId: user.id, 
        email: user.email, 
        type: provider, 
        token: token.accessToken,
        avatar: user.profilePicURL
    }
    
    return api.post('/social', data)
    .then(data => transformIn(data))
    .catch( error => {
        if (error.code === 401) {
            return Promise.reject({message: `Invalid ${data.provider} Token`});
        }
        return Promise.reject(error);
    });
}

const logout = _ => {
    return api.delete('/token')
    .catch( error => {
        return Promise.reject(error);
    })
}

const register = data => {
    return api.post('/users', data);
}

const signUpTokenValidate = token => {
    return api.put(`/registerValidation?token=${token}`)
    .then( data => ({
        ...data,
        id: data.userId,
        roleId: RoleIds.CLIENT
    }));
}

const getLoggedUser = _ => {
    return get('me')
    .then(data => transformIn(data));
}

const updateLoggedUser = data => {
    return update('me', transformOut(data));
}

const passwordChangeRequest = email => {
    return api.post('/forgotPasswordRequest', {email})
}

const passwordChange = (data) => {
    return api.post('/forgotPasswordValidate', data)
}

const passwordChangeValidateToken = (type, token) => {
    return api.get(`/checkForValidToken/${type}/${token}`)
    .then(data => data)
}

const listAll = () =>{
    return api.get(`/users`)
    .then(data => data);
}

const createPassword = (data) => {
    return api.put(`/registerValidation`, data)
    .then(data => data);
}

const get = (id) =>{
    return api.get(`/users/${id}`)
    .then(data => transformIn(data));
}

const update = (id, data) => {
    return api.put(`/users/${id}`, data)
    .then(data => transformIn(data));
}

const remove = (id) => {
    return api.delete(`/users/${id}`);
}

const getAdmins = () => {
    return api.get(`/users/role/${RoleIds.ADMIN}`) 
    .then(data => data)
}

const getImage = (url) => {
    return api.image(url);
}

const getContactModes = () => {
    return api.get('/users/contact-modes');
}

const getUserFiles = (userId) => {
    return api.get(`/media/all/user/${userId}`);
}

const downloadFile = api.download;

const removeFile = (fileId) => {
    return api.delete(`/media/${fileId}`);
}

const updateFile = (fileId, data) => {
    return api.put(`/media/${fileId}`, data);
}

export const UserService = {
    login,
    logout,
    socialAuthTokenValidate,
    register, 
    getLoggedUser, 
    updateLoggedUser,
    signUpTokenValidate,
    passwordChangeRequest,
    passwordChange,
    get,
    getImage,
    update,
    remove,
    getAdmins,
    getContactModes,
    listAll,
    createPassword,
    getUserFiles,
    removeFile,
    downloadFile,
    updateFile,
    passwordChangeValidateToken
}