import './Project.Header.scss';
import React from "react";
import { Progress } from 'antd';

export default (props) => {
    const { project, current, path } = props;
    let percent = current*12.5;
    if (project.progress){
        if(project.progress[path]){
            percent = 100;
        }
    }
    return (
    <div className="project-header">
        <Progress
            type="circle"
            strokeLinecap="square"
            strokeWidth={15}
            strokeColor={{
                '0%': '#8D9EBC',
                '100%': '#27326B'
            }}
            percent={percent}
            width={50}
        />
    </div>
);
}
