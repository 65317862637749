import './RateImage.scss';
import React from "react";
import { Rate } from 'antd';


class RateImage extends React.Component {
    state ={ value: []}

    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
            return { value: (nextProps.value) };
        }
        return null;
    }

    triggerChange = (value) => {
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(value);
        }
    }

    render() {
        const { value } = this.state;
        return (
            <div className="rate-image">
                <img src={this.props.data} alt=""/>
                <Rate onChange={this.triggerChange} value={value } allowClear={false}/>
            </div>
        );
    }
}

export {RateImage};