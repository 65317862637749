import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ProjectActions as actions } from '../actions';
import Form, { FormItem } from '../components/Form';
import { Col, Row } from 'antd';

class FormQuiz extends Component {
    
    static propTypes = {
        id: PropTypes.number,
        model:  PropTypes.array.isRequired,
        initialValues: PropTypes.object,
        onSubmit:  PropTypes.func,
    }

    constructor(props){
        super(props);
        this.form = React.createRef();
    }

    handleValuesChange = changed => {
        this.props.setQuizAnswers(this.props.id, changed);
    };

    render () {
        const { model, initialValues, id, children, onSubmit, disabled} = this.props;
        const gutter = 32;
        return (
             <Form spin={!id} 
                disabled={disabled}
                wrappedComponentRef={(inst) => this.form = inst} 
                initialValues={initialValues}
                onSubmit={onSubmit}
                onValuesChange={this.handleValuesChange}>

                <Row gutter={gutter}>
                    {model.map((item, index) => {
                        const span = item.size ? (item.size || 24) : 24;
                        return (
                            <Col lg={span} key={`column-${index}`}>
                                <FormItem key={item.name} {...item}/>
                            </Col>
                        )
                    })}
                </Row>
                <Row gutter={gutter}>
                    { children }
                </Row>
            </Form>      
        );
      }
}

const mapDispatchToProps  = (dispatch, ownProps) => {
    const { quizName } = ownProps;
    return {
        setQuizAnswers: (id, data) => {
            return dispatch(
                actions.setQuizAnswers(id, quizName, data)
            );
        },
    };
}

export default connect(null, mapDispatchToProps)(FormQuiz);