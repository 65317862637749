import React from "react";
import { Menu, Icon } from 'antd';
import { Link } from 'react-router-dom';

const SubMenu = Menu.SubMenu;

const IconDone = <Icon type="check-circle" theme="filled" />;
const IconEmpty = <Icon type="circle" />;

const getIcon = (done) => {

    return  done === true ? IconDone : done === false ? IconEmpty : null;
}

const getMenu = (item, progress) => {
    if(!progress)
        progress = {};
    
    if(item.children){
        const childsWithProgress = item.children
            .filter(i => Object.isDefined(i.progress));

        item.progress = childsWithProgress.length ? childsWithProgress.every(i => i.progress === true) : false;

        return (
            <SubMenu 
                key={item.key || item.link}
                className={item.progress ? 'completed': ''} 
                title={<Link to={item.link}>
                    {item.label} { getIcon(item.progress) }
                </Link>}>
                {item.children.map(i => getMenu(i, progress))}
            </SubMenu>)
    }
    else {
        if(Object.isDefined(item.progress)){
            item.progress = !!progress[item.link];
        }
        
        return (
            <Menu.Item 
                key={item.key ||item.link}
                className={item.progress ? 'completed': ''}
            >
                <Link to={item.link}>
                    {item.label} { getIcon(item.progress) }
                </Link>
            </Menu.Item> 
        )
    }
}

class TreeMenu extends React.Component {
    index = {};

    state = { openKeys: []}
    
    buildIndex = (root, children) => {
        for(var i in children) {
            this.index[children[i].key] = root;
            this.buildIndex(children[i].key, children[i].children);
        }
    }

    getPath = (leaf) => {
        return this.index[leaf] ? this.getPath(this.index[leaf]).concat([leaf]) : [leaf];
    }

    setOpenKeys = (selectedKey) => {
        const openKeys = this.getPath(selectedKey);
        this.setState({openKeys});
    }

    componentWillReceiveProps(nextProps){
        if(Object.isEmpty(this.index) || nextProps.data !== this.props.data){
            this.buildIndex(false, nextProps.data);
            this.setOpenKeys(nextProps.selectedKey)
        }
        else if(nextProps.selectedKey !== this.props.selectedKey){
            this.setOpenKeys(nextProps.selectedKey)
        }
   }

    handleOpenChange = (openKeys) => {
        this.setState({ openKeys: openKeys});
    }
    
    render () {
        const { data, selectedKey, progress } = this.props;
        const { openKeys } = this.state;

        return (
            <Menu
                mode="inline"
                theme="light"
                selectedKeys={[selectedKey]}
                openKeys={openKeys}
                onOpenChange={this.handleOpenChange}
                >
                {data.map(item => getMenu(item, progress))}       
            </Menu>
        )
    }
}

export default TreeMenu;