import React, { Component } from 'react';

import FormLogin from '../containers/FormLogin';
import FormPasswordChangeRequest from '../containers/FormPasswordChangeRequest';

const Forms = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  PASSWORD: 'PASSWORD',
};

const ContentPassword = ({toggleForms}) => (
    <div>
      <h1>Reset Password</h1>
      <p>Please, enter your e-mail address and you will receive instructions on how to reset your password.</p>

      <FormPasswordChangeRequest />

      <div className="links">
          <div
              className="btn-link"
              onClick={toggleForms(Forms.LOGIN)}
              id="btnToggleForms">
                  Back to Login
          </div>
      </div>
    </div>
);

const ContentLogin = ({toggleForms}) => (
    <div>
      <h1>Login</h1>
      <FormLogin />
      <div className="links">
        <div
            className="btn-link"
            onClick={toggleForms(Forms.PASSWORD)}>
                Forgotten Password?
        </div>
      </div>
    </div>
  )


class LoginSider extends Component {
    state = { currentForm: Forms.SIGNUP }

    toggleForms = currentForm => () => {
        this.setState({ currentForm });
    }

    render() {
        const { currentForm } = this.state;
        
        switch(currentForm){
            case Forms.PASSWORD: {
                return <ContentPassword toggleForms={this.toggleForms}/>
            }
            // @TODO (this must work for admins only) 
            // case Forms.SIGNUP: {
            //     return <ContentSignUp toggleForms={this.toggleForms}/>
            // }
            default: {
                return <ContentLogin toggleForms={this.toggleForms}/>;
            }
        }
    }
}
export default LoginSider;
