import { ProjectConstants as constants } from '../constants';
import { transformSettings } from './index';
import { isProjectDisabled } from '../models';

const initialState = Object.freeze({
    'quiz-style': {},
    'quiz-about': {},
    statuses: [],
});

export function project(state = initialState, action) {
    let project = {
        'quiz-style': {},
        'quiz-about': {},
        disabled: false,
        ...state,
    }

    switch (action.type) {
        case constants.PROJECT_STATUSES_LOADED: {
            project = {
                'quiz-style': {},
                'quiz-about': {},
                disabled: false,
                statuses: action.statuses,
            }
            break;
        }
        case constants.PROJECT_CREATED:
        case constants.PROJECT_CLEAR: {
            project = {
                'quiz-style': {},
                'quiz-about': {},
                disabled: false,
                statuses: state.statuses,
            }
            break;
        }
        case constants.PROJECT_LOADED: {
            project = {
                'quiz-style': {},
                'quiz-about': {},
                disabled: false,
                ...action.project,
                statuses: state.statuses,
            }
            break;
        }
        case constants.PROJECT_UPDATED: {
            project = {
                ...project,
                ...action.project,
                statuses: state.statuses,
            }
            break;
        }
        case constants.PROJECT_SETTINGS_LOADED: {
            const settings = transformSettings(action.settings, {});
            project = {
                ...project,
                ...settings,
                statuses: state.statuses,
            }
            break;
        }
        case constants.PROJECT_SETTINGS_CHANGED: {
            const settings = transformSettings(action.settings, state);
            project = {
                ...project,
                ...settings,
                statuses: state.statuses,
            }
            break;
        }
        default:
            break;
    }
    
    const status = project.statuses.find(s => s.id === project.statusId) || {name: 'N/A'};
    return {
        ...project,
        disabled: isProjectDisabled(project.statusId),
        statusTitle: status.name,
    }
}