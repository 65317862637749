import React from "react";
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import {RateImage} from './RateImage';

class RateImages extends React.Component {
    state ={ value: {}}

    static propTypes = {
        props: PropTypes.object,
    }
    static defaultProps = {
        props: { size: 8 },
    }

    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
            return { value: (nextProps.value) || {} };
        }
        return null;
    }

    triggerChange = (value, item) => {
        const onChange = this.props.onChange;
        if (onChange) {
            const res = { ...this.state.value, [item] : value }
            onChange(res);
        }
    }

    render() {
        const { value } = this.state;
        const { options, props } = this.props;
        return (
            <Row gutter={32}>
                {options.map((item, index) => {
                    const key = `${item.url}-${index}`;
                    return (
                        <Col span={ props.itemsize ?  props.itemsize * 2 : 8} key={index} >
                            <RateImage data={item.image} 
                                value={value[key]} 
                                onChange={(value) => this.triggerChange(value, key)}/>
                        </Col>
                    )
                })}
            </Row>
        );
    }
}

export {RateImages};