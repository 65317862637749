import './CheckRadioGroup.scss';
import React from 'react';
import { Row, Col, Radio } from 'antd';

const RadioButton = Radio.Button;


class RadioGroup extends React.Component {
    state ={ value: undefined};

    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
            let value = nextProps.value;
            if(Object.isObject(value) && value.value){
                value = value.value;
            }
            return { value: value };
        }
        return null;
    }

    triggerChange = (e) => {
        let value = e.target.value;
        const onChange = this.props.onChange;
        
        if (onChange) {
            const option = this.props.options.find(item => item.value === value);
        
            if(option && option.description){
                onChange({
                    value: value, 
                    description: option.description
                });
            } 
            else
                onChange(value);
        }
    }

    render() {
        const { value } = this.state;
        const { options, itemsize } = this.props;
        
        if(options.length && options[0].image){
             
            return (
                <Radio.Group 
                    className="check-images"
                    onChange={this.triggerChange} 
                    value={value}>
                    <Row gutter={32}>
                        {options.map((item, index) => {
                            return (
                                <Col span={itemsize ? itemsize * 2 : 8} key={index} >
                                    <Radio value={item.value} valuePropName="value">
                                        <img src={item.image} alt=""/>
                                        <div>{item.text}</div>
                                    </Radio>
                                </Col>
                            )
                        })}
                    </Row>
                </Radio.Group>
            )
        }
        return (
            <Radio.Group
                buttonStyle="solid" 
                onChange={this.triggerChange} 
                value={value}>
                {options.map((item, index) =>(
                    <RadioButton 
                        key={index} 
                        value={item.value}>
                       
                        {item.tooltip ? (<span><b>{item.text}</b>{item.tooltip}</span>) : item.text}
                    </RadioButton>
                ))}
            </Radio.Group>
        );
    }
}

export { RadioGroup };