import React from "react"
import { Row, Col } from 'antd';

const AccessDenied = () => {
        
    return (
        <Row type="flex" justify="space-around" align="middle">
            <Col xl={6}>
                <h1>Access Denied</h1>
            </Col>
        </Row>
    );
}

export default AccessDenied;