import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import {
    Layout,
    Row,
    Col,
} from 'antd';
import ReactGA from 'react-ga';
import { RootRoutes } from '../routes';
import { UserActions as actions, BuilderActions } from '../actions';
import { BuilderService as service } from '../services';
import CardUser from '../containers/CardUser';
import { RoleNames, RoleIds } from '../models';

const { Content, Footer, Header } = Layout;
const basePath = process.env.REACT_APP_BASEPATH || '';
const googleTrackingId = process.env.GOOGLE_TRACKING_ID || 'UA-131679574-1';

const getClassName = (location, roleId = 0) => {
    const first = location.pathname.replace(/^\/([^/]*).*$/, '$1');
    return `dw-${(first || 'home')} ${RoleNames[roleId].toLowerCase()}`;
};

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            logo: '',
            isLogoLoaded: false,
            STATE_builderId: null
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        const { builderId, roleId } = props;
        const { isLogoLoaded } = this.state;
        this.loadLogo(isLogoLoaded, roleId, builderId);
    }

    componentDidMount() {
        const { history, location, getLoggedUser } = this.props;
        ReactGA.initialize(googleTrackingId);
        
        getLoggedUser();

        this.gaLogPageview(location);
        history.listen((location) => {
            this.gaLogPageview(location);
        });
    }

    loadLogo = async (isLogoLoaded, roleId, builderId) => {
        const { STATE_builderId } = this.state;
        let isLoaded = isLogoLoaded;
        if (STATE_builderId !== builderId) isLoaded = false;
        if (!isLoaded && RoleIds.CLIENT === roleId && builderId) {
            const data = await BuilderActions.getLogo('builder', builderId);
            const logo = await service.getImage(data.data);
            this.setState({ logo, isLogoLoaded: true, STATE_builderId: builderId });
        }
    }

    gaLogPageview = (location) => {
        ReactGA.pageview(
            location.pathname,
            location.search
        );
    }

    render() {
        const {
            loggedIn, history, location, roleId, logout, projectId
        } = this.props;

        const { logo } = this.state;

        const className = getClassName(location, roleId);
        return (
            <ConnectedRouter basename={basePath} history={history} spinning>
                <Layout className={className}>
                    <Header>
                        <div className="container">
                            {
                                (loggedIn && RoleIds.CLIENT === roleId && logo) ?
                                    <div className="logo" >
                                        <Link to={`/project/${projectId}`}>
                                            <img className="builder-logo" src={logo} alt="" />
                                        </Link>
                                    </div> :
                                    <Link to="/">
                                        <div className="bdc-logo" />
                                    </Link>
                            }
                            <CardUser logout={logout} />
                        </div>
                    </Header>
                    <Content>
                        <div className="container">
                            <RootRoutes loggedIn={loggedIn} />
                        </div>
                    </Content>
                    <Footer>
                        <div className="container">
                            <Row>
                                <Col xs={0} md={9}>
                                    <img src="/images/logo.png" alt="BDC" />
                                </Col>
                                <Col xs={8} md={5}>
                                    <h4>Wasatch Central</h4>
                                    <span>
                                        9657 South 600 West<br />
                                        Sandy, Utah 84070
                                    <p>
                                            801.619.0200
                                    </p>
                                    </span>
                                </Col>
                                <Col xs={8} md={5}>
                                    <h4>Treasure Valley</h4>
                                    <span>
                                        96 South Baltic Place<br />
                                        Meridian, Idaho 83642
                                    <p>
                                            208.288.5541
                                    </p>
                                    </span>
                                </Col>
                                <Col xs={8} md={5}>
                                    <h4>Phoenix</h4>
                                    <span>
                                        20620 N. 19th Ave, Ste 110<br />
                                        Phoenix, AZ 85027
                                    <p>
                                            623.516.2700
                                    </p>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    </Footer>
                </Layout>
            </ConnectedRouter>
        );
    }
}

App.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    builderId: PropTypes.number
}

const mapStateToProps = (state) => {
    const { user } = state.authentication;
    return {
        loggedIn: state.authentication.loggedIn,
        location: state.router.location,
        roleId: user.roleId || 0,
        projectId: user.projectId || 0,
        builderId: user.builderId,
    };
};

const mapDispatchToProps = dispatch => ({
    getLoggedUser: () => dispatch(actions.getLoggedUser()),
    logout: () => dispatch(actions.logout()),
});


export default connect(mapStateToProps, mapDispatchToProps)(App);
