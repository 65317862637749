import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Collapse, Row, Col, Button, Icon } from 'antd';
import { ProjectActions as actions } from '../../actions';
import { ProjectModel as model, ProjectReadyState } from '../../models';
import { history } from '../../helpers';
import Form, { FormItem } from '../../components/Form';

import { UserService, ProjectService } from '../../services';

import ProjectVideo from '../Project.Video';
import ProjectStyle from './Project.Style';
import ProjectInspiration from '../Project.Inspiration';
import ProjectVisualWorx from '../Visualizer';
import ProjectAbout from './Project.About';
import ProjectDocuments from './Project.Documents';


const { Panel } = Collapse;
const { isDoneProject } = ProjectReadyState;
const IconDone = (
    <Icon
        type="check-circle"
        theme="filled"
        onClick={(event) => {
            event.stopPropagation();
        }}
    />
);
const IconNotDone = (
    <Icon type="circle" />
);

const panelItems = [
    {
        header: 'INTRO',
        iconFn: (project) => {
            return (
                isDoneProject(project, '/intro') ? IconDone : IconNotDone
            )
        },
        component: ProjectVideo,
    },
    {
        header: 'ABOUT YOU',
        iconFn: (project) => (
            isDoneProject(project, '/about-you') ? IconDone : IconNotDone
        ),
        component: ProjectAbout,
    },
    {
        header: 'STYLE',
        iconFn: (project) => (
            isDoneProject(project, '/style') ? IconDone : IconNotDone
        ),
        component: ProjectStyle,
    },
    {
        header: 'INSPIRATION',
        iconFn: (project) => (
            isDoneProject(project, '/inspiration') ? IconDone : IconNotDone
        ),
        component: ProjectInspiration,
    },
    {
        header: 'VISUALWORX',
        iconFn: (project) => (
            isDoneProject(project, '/visualworx') ? IconDone : IconNotDone
        ),
        component: ProjectVisualWorx,
    },
    {
        header: 'DOCUMENTS',
        iconFn: (project) => (
            isDoneProject(project, '/documents') ? IconDone : IconNotDone
        ),
        component: ProjectDocuments,
    }
];

class AdminProjectDetails extends Component {
    unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            statusOptions: []
        };
    }

    componentDidMount() {
        const { get, params } = this.props;
        const { id } = params;
        if (id) {
            get(id);
            ProjectService.getStatuses()
                .then((data) => {
                    const statusOptions = data.map(item => ({
                        value: item.id,
                        text: item.name,
                    }));
                    if (!this.unmounted)
                        this.setState({ statusOptions });
                });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { project } = nextProps;
        if (!this.state.loaded && project.id) {
            UserService.get(project.userId)
                .then(user => this.setState({
                    loaded: true,
                    projectOwner: `${user.firstName} ${user.lastName}`
                }));
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    goBack = () => {
        history.goBack();
    }

    handleSubmit = values => this.props.update(this.props.project.id, values)

    render() {
        const { project, params } = this.props;
        const { statusOptions } = this.state;
        return (
            <div>
                <Form
                    initialValues={project}
                    onSubmit={this.handleSubmit}
                >
                    <Row gutter={32}>
                        <Col lg={model.statusId.size}>
                            <FormItem {...{ ...model.statusId, options: statusOptions }} />
                        </Col>
                    </Row>
                    <Row>
                        <Button
                            type="primary left"
                            htmlType="submit">
                            Save
                        </Button>
                    </Row>
                </Form>

                <Collapse accordion bordered>
                    {panelItems.map((item, index) => (
                        <Panel
                            header={item.header}
                            key={index}
                            extra={item.iconFn(project)}
                        >
                            {
                                React.createElement(item.component, {
                                    hideButtons: true,
                                    params,
                                    userId: project.userId,
                                    role: "admin"
                                })}
                        </Panel>
                    ))}
                </Collapse>
            </div>
        );
    }
}


const mapStateToProps = ({ project = {} }) => ({
    project,
});

const mapDispatchToProps = dispatch => ({
    get: id => dispatch(actions.getAllData(id)),
    update: (id, values) => dispatch(actions.update(id, values))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminProjectDetails);
