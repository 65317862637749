import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer, { routingMiddleware } from './reducers';
import { history } from './helpers/history.helpers';
// import { logger  } from 'redux-logger';

export default createStore(
    createRootReducer(history),
    compose(
        applyMiddleware(
            routerMiddleware(history), 
            routingMiddleware,
            thunkMiddleware,
            // logger
        ),
    )
);