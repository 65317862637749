import { ClientService as service, ProjectService } from '../services';
import { showResponseError } from '../helpers';

const get = (id) => {
    return service.get(id)
        .then(
            data => data,
            error => showResponseError({ ...error, source: '[client.get]' })
        );
}

const list = (...params) => service.list(...params)
    .then(
        data => data,
        error => showResponseError({ ...error, source: '[client.list]' })
    );

const listAdvanced = async (...params) => {
    const statuses = await ProjectService.getStatuses();
   
    return service.listAdvanced(...params)
    .then(
        data => {
            const clients = data.data.map (client => {
                const status = statuses.find(s => s.id === client.projectStatusId) || {name: 'N/A'};
                return { ...client, projectStatusId: status.name };
            });
            return { data: clients, meta: data.meta}
        },
        error => showResponseError({ ...error, source: '[client.list]' })
    );
}

const create = values => service.create(values)
    .then(
        data => data,
        error => showResponseError({ ...error, source: '[client.create]' })
    );

const update = (id, values) => service.update(id, values)
    .then(
        data => data,
        error => showResponseError({ ...error, source: '[client.update]' })
    )
    .catch(error => showResponseError(error));

export const ClientActions = {
    list,
    listAdvanced,
    get,
    create,
    update,
};