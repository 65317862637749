import React from "react"
import { connect } from 'react-redux';
import { Spin } from 'antd';
import HomePublic from './Home.Public';
import HomeClient from './Project';
import HomeAdmin from './admin';
import { RoleIds } from "../models";

const Home = props => {
    const { loggedIn, roleId, loggingIn } = props;
    
    if( loggingIn ) 
        return <Spin tip="Logging In" style={{ marginTop: 200 }} />;

    if(!loggedIn)
        return <HomePublic {...props} />;
    
    if  (roleId === RoleIds.ADMIN) 
        return <HomeAdmin {...props}/>;

    return <HomeClient {...props}/>;
}

const mapStateToProps = ( {authentication: { loggedIn, loggingIn, user: { roleId } } }) => {
    return {
          loggedIn,
          loggingIn, 
          roleId,
      };
};

export default connect(mapStateToProps)(Home);