import './Slider.scss';
import React from "react";
import { Checkbox as CheckboxRaw } from 'antd';


class Checkbox extends React.Component {
    state ={ checked: false}

    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
            return { checked: (nextProps.value) };
        }
        return null;
    }

    triggerChange = (value) => {
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(value);
        }
    }

    render() {
        const { props } = this.props;

        const meta = this.props['data-__meta'];

        const { checked } = this.state;
        return (
            <CheckboxRaw 
                checked={checked} 
                onChange={this.triggerChange}
                {...props}>{ meta.displayName }
            </CheckboxRaw>
        );
    }
}

export { Checkbox };