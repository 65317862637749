import api from './api';
import { toQueryParams } from '../helpers';

const get = id => {
    if(!id)
        return api.get()
        .then(data => data.data);

    return api.get(`/clients/${id}`);
}
const list = () => {
    return api.get( `/clients`)
    .then(data => data.data);
}

const listAdvanced = (page = 1, take = 500, filters = {}, sort = "id+DESC") => {
    const params = toQueryParams({
        page: page,
        per_page: take,
        order_by: sort,
        filters: filters
    });
    return api.get(`/users-advanced?${params}`);
}

const create = (data) => {
    return api.post('/clients', data);
}

const update = (id, data) => {
    return api.put(`/clients/${id}`, data);
}

export const ClientService = {
    list,
    listAdvanced,
    get,
    create,
    update,
};