import '../assets/sider.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import TreeMenu from '../components/TreeMenu';

const getNavigation = (projectId) => {
    const urlBase = `/project/${projectId}`;
    return [
        { 
            label: 'Dashboard', 
            link: `${urlBase}`, 
        },
        {   
            label: 'Getting Started', 
            key: `${urlBase}/getting-started`, 
            link: `${urlBase}/intro`, 
            children: [
                { 
                    label: 'Video Intro', 
                    key: `${urlBase}/intro`, 
                    link: `${urlBase}/intro`,
                    progress: true 
                },
                { 
                    label: 'Let`s learn about you',
                    key: `${urlBase}/about-you`, 
                    link: `${urlBase}/about-you`,
                    progress: true 
                },
                { 
                    label: 'What`s your style?',
                    key: `${urlBase}/style`, 
                    link: `${urlBase}/style`,
                    progress: true 
                },
                { 
                    label: 'Inspiration',
                    key: `${urlBase}/inspiration`, 
                    link: `${urlBase}/inspiration`,
                    progress: true 
                },
            ],
        },
        { 
            label: 'VisualWorx', 
            link: `${urlBase}/visualworx`, 
        },
        { 
            label: 'Documents', 
            link: `${urlBase}/documents`,
        },
    ];
}

class ProjectSider extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            navigation: [],
        };
    }
    
    static getDerivedStateFromProps(props, state) {
        const { projectId } = props;
        
        return {
            navigation: getNavigation(projectId),
        };
    }
    
    render() {
        const { navigation } = this.state;
        let { path, project } = this.props;
        const { progress } = project;
        return (
            <TreeMenu
                selectedKey={path}
                data={navigation}
                progress={progress}
            /> 
        );
    }
}

const mapStateToProps = ({ router, project }) => {
    return { 
        path: router.location.pathname,
        project
    };
};

export default connect(mapStateToProps)(ProjectSider);
