
import api from './api';

const getDomain = (name, first) => {
    return api.get(`/warcott/${name}`)
    .then(
        data => {
            if(first){
                return data && data.fieldsets && data.fieldsets.length ? 
                        data.fieldsets[0] 
                    : { fields: []}
            }
            else {
                return data.fieldsets;
            }
        }
    )
}


export const WarcottService = {
    getDomain,
};