import { ColumnTypes } from '../components/DataTable';
// import { ProjectService } from '../services'

// let ProjectStatusEnum = [];
// (async function () {
//     ProjectStatusEnum = await ProjectService.getStatuses();
// })();

// const ProjectStatusFilter = () => {
//     const d = ProjectStatusEnum;
//     return Object.keys(d).map(key => ({
//         text: d[key].name,
//         value: d[key].id
//     }))
// }

export const ClientListModel = [
    {
        title: 'Id',
        key: 'id',
        type: ColumnTypes.number,
        filter: true,
    },
    {
        title: 'First Name',
        key: 'firstName',
        type: ColumnTypes.string,
        filter: true,
    },
    {
        title: 'Last Name',
        key: 'lastName',
        type: ColumnTypes.string,
        filter: true,
    },
    {
        title: 'Status',
        key: 'projectStatusId',
        type: ColumnTypes.string,
        filter: [
            { value: 1, text:"Appointment scheduled" },
            { value: 2, text:"Design in progress" },
            { value: 3, text: "Design complete" },
            { value: 4, text: "Abandoned" }
        ],
        render: (val) => val,
        // render: (val) => getProjectStatus(val),
    },
    {
        title: 'E-mail',
        key: 'email',
        type: ColumnTypes.string,
        filter: true,
    },
    {
        title: 'Phone',
        key: 'phoneNumber',
        type: ColumnTypes.string,
        filter: true,
    },
    {
        title: 'Builder',
        key: 'builder',
        type: ColumnTypes.string,
        filter: true,
    },
    {
        title: 'Subdivision',
        key: 'subdivision',
        type: ColumnTypes.string,
        filter: true,
    },
    {
        title: 'Lot Number',
        key: 'lotNumber',
        type: ColumnTypes.string,
        filter: true,
    },
    {
        title: 'Home Plan Name',
        key: 'homePlanName',
        type: ColumnTypes.string,
        filter: true,
    },
    {
        title: 'Registration date',
        key: 'createdAt',
        type: ColumnTypes.time,
    },
    {
        title: 'Time of last activity',
        key: 'lastLogin',
        type: ColumnTypes.time,
    },
];