import './SortableList.scss';
import React from "react";
import ListSort from "../ListSort";
import { Icon } from 'antd';

class SortableList extends React.Component {
    state ={ value: []}

    static getDerivedStateFromProps(nextProps, nextState) {
        if(nextState.value.length)
            return null;
        if ('value' in nextProps) {
            return { value: (nextProps.value || []) };
        }
        return null;
    }

    triggerChange = (items) => {
        const onChange = this.props.onChange;
        if (onChange) {
            const res = items.map(item => item.props.children[2]);
            onChange(res);
        }
    }

    handleEventChange = (e, type) => {
        this.setState({dragged: (type === 'down')})
    }

    render() {
        const { value, dragged } = this.state;
        const items = Array.isArray(value) && value.length ? value : this.props.options;

        return (
            <div className={`list-sort-wrapper ${dragged?'ready' : ''}`}>
                <ListSort
                    dragClassName="list-drag-selected"
                    appearAnim={{ animConfig: { marginTop: [5, 30], opacity: [1, 0] } }}
                    onChange={this.triggerChange}
                    onEventChange={this.handleEventChange}>
                    {items.map((item, index) => {
                        return (
                            <div key={index} className={`list-sort-item`}>
                                <Icon type="arrows-alt" rotate={-45} /> {item}
                            </div>
                        );
                    })}
                </ListSort>
            </div>
        );
    }
}

export {SortableList};