import React from 'react';
import PropTypes from 'prop-types';
import { Component } from 'react'
import { Form, Spin } from 'antd';
import FormContext from './FormContext';
import { debounce} from '../../helpers';

const DebounceSec = 0.5; 
export { FormItem } from './FormItem';
 
class FormWithContext extends Component {   
    unmounted = false;

    static propTypes = {
        form: PropTypes.object.isRequired,
        onSubmit: PropTypes.func,
        hideRequiredMark: PropTypes.bool,
        layout: PropTypes.string,
        initialValues: PropTypes.object,
        spin: PropTypes.bool,
        submitOnEnter: PropTypes.bool,
    }

    static defaultProps = {
        hideRequiredMark: false,
        layout: 'vertical',
        initialValues: undefined,
        spin: true,
        submitOnEnter: true,
    }
    
    constructor(props) {
        super(props);
        this.form = props.form;
        this.state = {spinning: false}
    }
    
    componentWillUnmount (){
        this.unmounted = true;
    }

    validateFields = (names, callback)  => this.form.validateFields(names, { force: true }, callback);

    getFieldValue = (name) => this.form.getFieldValue(name);

    getFieldsValue = () => this.form.getFieldsValue();

    setFieldsValue = ( fields ) => this.form.setFieldsValue(fields)

    submit = () => this.handleSubmit()

    /*
    ** This doesn't work on dynamic fields
    componentDidMount(){
        if(this.props.initialValues)
            this.setInitialValues(this.props.initialValues);
    }

    componentWillReceiveProps(nextProps){
        const initialValuesChanged = !Object.equals(this.props.initialValues, nextProps.initialValues);
        if(initialValuesChanged){
            this.setInitialValues(nextProps.initialValues);
        }
    }

    setInitialValues = (values) => {
        if(Object.isEmpty(values))
            return;
        
        const filtered = Object.keys(values)
            .filter(key => !!this.form.getFieldInstance(key))
            .reduce((obj, key) => {
                obj[key] = values[key];
                return obj;
            }, {});

        this.form.setFieldsInitialValue(filtered);
        this.forceUpdate();
    }
    */

    handleSubmit = (e) => {
        if(e)
            e.preventDefault();
        this.form.validateFields((err, values) => {
            if (!err) {
                if(this.props.spin)
                    this.setState({ spinning: true });
                const result = this.props.onSubmit(values);
                if(result)
                    return Promise.resolve(result).finally(() => {
                        if(this.unmounted)
                            return;
                        if(this.props.spin)
                            this.setState({ spinning: false });
                            this.form.resetFields()
                    });
            }   
            return Promise.resolve(values);        
        });
    }

    onKeyDown = e => {
        return e.keyCode === 13 ? e.preventDefault(): '';
    }

    render () {
        const { 
            form, layout, hideRequiredMark, 
            spin, initialValues, submitOnEnter,
            disabled,
        } = this.props;

        const { spinning } = this.state;

        const contextValue = { 
            getFieldDecorator: form.getFieldDecorator,
            getFieldValue: form.getFieldValue,
            initialValues: initialValues,
            currentValues: form.getFieldsValue
         };

        return (
            <Spin spinning={spin && (initialValues === undefined || spinning)}>                
                <Form 
                    disabled={disabled}
                    noValidate 
                    layout={layout}  
                    hideRequiredMark={hideRequiredMark} 
                    onSubmit={this.handleSubmit}
                    onKeyDown={submitOnEnter === false ? this.onKeyDown : undefined}>
                    
                    <FormContext.Provider value={contextValue}>
                        { this.props.children }
                    </FormContext.Provider>
                </Form>
            </Spin>           
        );
    }
}

const onValuesChange = (props, changed, all) => {
    
    const changeFn = (changed, all) => {
        const names = Object.keys(changed);
        props.form.validateFields(
            names, 
            error => {
                if(!error){
                    props.onValuesChange(changed, all);
                }
            }
        )
    };
    const debounceFn = debounce(changeFn, DebounceSec * 1000);
    if(props.onValuesChange){  
        debounceFn(changed, all);
    }
}

export default Form.create({onValuesChange})(FormWithContext);