import React, { Component } from "react"
import {
    Button,
    Row,
    Col,
    notification,
    Icon
} from 'antd';

import { BuilderActions as actions } from '../actions';
import { BuilderModel as model } from '../models';

import Form, { FormItem } from '../components/Form';

class CreateBuilder extends Component {

    state = { confirmDirty: false }

    handleSubmit = (values) => {
        const { toggleModal } = this.props;
        values.confirm = undefined;
        return actions.create(values)
            .then(() => {
                notification.success({
                    message: 'A new builder has been created successfully',
                    duration: 2,
                    icon: <Icon type="check-circle" theme="filled" style={{ color: "#52B799", fontWeight: "bolder" }} />,
                    onClose: notification.destroy(),
                    description:
                        'Builder has been created!',
                })
            })
            .then(() => {
                toggleModal(true)
                setTimeout(() => {
                    notification.destroy()
                }, 2000)
            })
    }

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }

    render() {
        const thisModel = {
            number: model.number,
            name: model.name,
            email: model.email,
            phone: model.phone,
        }
        return (
            <Form initialValues={{}}
                hideRequiredMark={true}
                wrappedComponentRef={(inst) => this.form = inst}
                onSubmit={this.handleSubmit}>
                <Row gutter={20}>
                    <Col span={thisModel.number.size}>
                        <FormItem {...thisModel.number} />
                    </Col>
                    <Col span={thisModel.name.size}>
                        <FormItem {...thisModel.name} />
                    </Col>
                    <Col span={thisModel.email.size}>
                        <FormItem {...thisModel.email} />
                    </Col>
                    <Col span={thisModel.phone.size}>
                        <FormItem {...thisModel.phone} />
                    </Col>
                </Row>
                <Button
                    block
                    type="primary"
                    size="large"
                    htmlType="submit">Create
                </Button>
            </Form>
        );
    }
}

export default CreateBuilder;