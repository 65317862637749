import { UserConstants as constants} from '../constants';
import { UserService as service } from '../services';
import { LocalData } from '../services';
import { showResponseError, showResponseMessage } from '../helpers';

const loginRequest = (user) => ({ type: constants.LOGIN_REQUEST, user });
const loginSuccess = (user) => ({ type: constants.LOGIN_SUCCESS, user });
const profileChangedSuccess = (user) => ({ type: constants.PROFILE_CHANGED, user });
const logoutSuccess = () => ({type: constants.LOGOUT_SUCCESS});
const loginFailure = (error) => ({ type: constants.LOGIN_FAILURE, error })

const login = (credentials) => {
    return dispatch => {
        dispatch(loginRequest(credentials));
        service.login(credentials)

        .then(
            user => { 
                dispatch(loginSuccess(user));
            },
            error => {
                dispatch(loginFailure(error));
                showResponseError(error);
            }
        );
    };
}

const socialAuthTokenValidate = (user) => {
    return dispatch => {
        dispatch(loginRequest(user));
        return service.socialAuthTokenValidate(user)
        .then(
            user => { 
                dispatch(loginSuccess(user));
            },
            error => {
                dispatch(loginFailure(error));
                showResponseError(error);
            }
        );
    };
}

const createPassword = (data) => {
    return service.createPassword(data)
        .then(
            data => data,
            error => showResponseError(error)
        )
}

const register = (data) => {
    return service.register(data)
    .then( 
        data => data, 
        error => showResponseError(error)
    );
}

const signUpTokenValidate = (token) => {
    return dispatch => {
        return service.signUpTokenValidate(token)
        .then(
            data => dispatch(loginSuccess(data))
        );
    }
}

const logout = () => {
    return dispatch => {
        const token = LocalData.token() 
        if(!token)
            return dispatch(logoutSuccess());
        service.logout()
        .then(
            data => { 
                dispatch(logoutSuccess());
            },
            error => dispatch(logoutSuccess())
        );
    }
}

const getLoggedUser = () => {
    return dispatch => {
        const token = LocalData.token() 
        if(!token)
            return Promise.resolve();
            
        dispatch(loginRequest({}));
        return service.getLoggedUser()
        .then(
            data => {
                const { projectStatusId } = data;
                if(projectStatusId === 4) {
                    showResponseError({message: 'Your project has been marked as cancelled. If you think this is an error, please contact us.'})
                    dispatch(logoutSuccess());
                }
                else {
                    dispatch(profileChangedSuccess(data))
                } 
            },
            error => dispatch(loginFailure(error)),
        )
    }
}

const updateLoggedUser = (values) => {
    return dispatch => {
        return service.updateLoggedUser(values)
        .then( 
            data => {
                dispatch(profileChangedSuccess(data))
            }, 
            error => showResponseError(error)
        );
    }
}

const updateLoggedUserAvatar = data => {
    return (dispatch) => {
        dispatch(profileChangedSuccess(data));
    }
}

const passwordChange = (data) =>{
    return service.passwordChange(data)
    .then( data => {
        showResponseMessage(`You've successfully changed your Password.`, 7)
    })
    .catch(error => showResponseError(error));
}

const passwordChangeRequest = (email) =>{
    return service.passwordChangeRequest(email)
    .then( data => {
        return showResponseMessage('E-mail Sent')
    })
    .catch(error => showResponseError(error));
}

const passwordChangeValidateToken = (type, token) => {
    return service.passwordChangeValidateToken(type, token)
    .then(data => data)
    .catch(error=> showResponseError(error));
}

const getContactModes = () => {
    return service.getContactModes();
    
}

const listAllStaff = () => {
    return service.getAdmins()
    .then(
        res => res,
        error => showResponseError(error)
    );
}


const listAllClients = () => {
    return service.listAll()
    .then(
        res => {
            const newData = res;
            const currData = newData.data;
            currData.forEach(e => {
                if (e.roleId === 1){
                    var i = currData.indexOf(e);
                    currData.splice(i, 1);
                }
                return e
            });
            return newData
        },
        error => showResponseError(error)
    );
}

const remove = (id) => {
    return service.remove(id)
    .then(
        data => data,
        error => showResponseError(error)
    );
};

export const UserActions = {
    login,
    logout,
    socialAuthTokenValidate,
    register,
    loginSuccess,
    logoutSuccess,
    signUpTokenValidate,
    passwordChangeRequest,
    passwordChange,
    getLoggedUser,
    updateLoggedUser,
    updateLoggedUserAvatar,
    getContactModes,
    remove,
    listAllStaff,
    listAllClients,
    createPassword,
    passwordChangeValidateToken
};
export {logoutSuccess};
