import { FieldRuleTypes, FieldTypes } from '../components/Form/config';
import{ ColumnTypes } from '../components/DataTable';

//@TODO BUILDER IDS 
// export const BuilderIds = Object.freeze({
//     PUBLIC: 0,
//     ADMIN: 1,
//     CLIENT: 2, 
//     ALL: [0, 1, 2]
// });

export const BuilderModel = {
    number: {
        name: 'number',
        displayName: 'ID Number',
        type: FieldTypes.INPUT,
        size: 6,
    },
    name: {
        name: 'name',
        displayName: 'Builder Name',
        validation: { required: true },
        type: FieldTypes.INPUT,
        size: 18,
    },
    email: {
        name: 'email',
        displayName: 'Builder E-mail',
        validation: { required: true, rule: FieldRuleTypes.EMAIL},
        type: FieldTypes.EMAIL,
        size: 24,
    },
    clientsNumber : {
        name: 'clientsNumber',
        displayName: 'Clients',
        validation: { required: true, rule: FieldRuleTypes.NUMBER },
        type: FieldTypes.INPUT,
        size: 24,
    },
    phone: {
        name: 'phone',
        displayName: 'Phone Number',
        validation: { required: true, rule: FieldRuleTypes.PHONE},
        type: FieldTypes.INPUT,
        size: 24,
    },
}


export const BuilderListModel = [
    {
        title: 'Id Number',
        key: 'number',
        dataIndex: 'number',
        type: ColumnTypes.string,
    },
    {
        title: 'Builder Name',
        key: 'name',
        dataIndex: 'name',
        type: ColumnTypes.string,
    },
    {
        title: 'Builder E-mail',
        key: 'email',
        dataIndex: 'email',
        type: ColumnTypes.string,
    },
    {
        title: 'Phone Number',
        key: 'phone',
        dataIndex: 'phone',
        type: ColumnTypes.number,
    },
    {
        title: 'Number of Clients',
        key: 'clientsNumber',
        dataIndex: 'clientsNumber',
        type: ColumnTypes.number,
    },
];