
import moment from 'moment';
import * as config from '../../config';

const dateFormat = config.DateFormat;
const dateTimeFormat = config.DateTimeFormat;

export const ColumnTypes = {
    string: 'string',
    number: 'number',
    date: 'date',
    time: 'time',
    bool: 'bool'
}

export const FilterPatterns = {
    LIKE: 'LIKE',
    IN: 'IN'
}

export const sortFn =  {
    [ColumnTypes.string]: (name) => { return  (a, b) => {
        if(!a[name] || !b[name])
            return 0;
        var c = a[name].toLowerCase(), d = b[name].toLowerCase();
        if (c < d) 
            return -1;
        if (c > d)
            return 1;
        return 0; 
    }}
    , [ColumnTypes.number]: (name) => {return (a, b) => a[name] - b[name]}
    , [ColumnTypes.time]: (name) => {return (a, b) => moment(a[name]).diff(moment(b[name]), "minutes")}
    , [ColumnTypes.date]: (name) => {return (a, b) => new Date(a[name]) - new Date(b[name]) }
    , [ColumnTypes.bool]: (name) => {return (a, b) => (a[name] === b[name])? 0 : a[name]? -1 : 1; }
}

export const renderFn = {
    [ColumnTypes.date]: (text, record, index) => {
        if(!text)
            return '';
        return moment(text).format(dateFormat);
    },
    [ColumnTypes.time]: (text, record, index) => {
        if(!text)
            return '';
        if(text.indexOf('0000') === 0)
            return '';
        return moment(text).local().format(dateTimeFormat);
    },
    [ColumnTypes.bool]: (text, record, index) => {
        return text? 'Yes' : 'No';
    },
    /*[ColumnTypes.number]: (text, record, number) => {
        if(!text)
            return '';
        return parseFloat(text).toFixed(2);
    }*/
}