import api from './api';
import moment from 'moment';
import { WarcottService } from './index';
import { FieldTypes } from '../components/Form/config';
import { toQueryParams } from '../helpers';

const signal = () => {
    return api.CancelToken.source();
}

const transformIn = data => {
    return { 
        ...data, 
        yearBuilt: data.yearBuilt ? moment().year(data.yearBuilt) : null, 
        adults: data.adults || 0,
        kids: data.kids || 0,
        expectingMoreKids: data.expectingMoreKids === 1,
        expectingMorePets: data.expectingMorePets === 1,
        expectingMoreAgingFamilyMembers: data.expectingMoreAgingFamilyMembers === 1,
    }  
}

const transformOut = data => {
    if('yearBuilt' in data)
        data.yearBuilt = data.yearBuilt ? data.yearBuilt.format('YYYY'): null;
    if('expectingMoreKids' in data)
        data.expectingMoreKids = data.expectingMoreKids ? 1 : 0;
    if('expectingMorePets' in data)
        data.expectingMorePets = data.expectingMorePets ? 1 : 0;
    if('expectingMoreAgingFamilyMembers' in data)
        data.expectingMoreAgingFamilyMembers = data.expectingMoreAgingFamilyMembers ? 1 : 0;
    return data;
}

const get = id => {
    if(!id)
        return api.get()
        .then(data => data.data);

    return api.get(`/projects/${id}`)
    .then(data => transformIn(data));
}
const list = () => {
    return api.get( `/projects`)
    .then(data => data.data);
}

const listAdvanced = (page = 1, take = 500, filters = {}, sort = "id+DESC") => {
    const params = toQueryParams({
        page: page,
        per_page: take,
        order_by: sort,
        filters: filters
    });
    return api.get(`/projects-advanced?${params}`);
}

const create = (data) => {
    return api.post('/projects', data)
    .then(data => transformIn(data));
}

const update = (id, data) => {
    return api.put(`/projects/${id}`, transformOut(data))
    .then(data => transformIn(data));
}

const getProjectTypes = _ => {
    return api.get('projects/home-types')
    .then( data => data.data);
}

const getImages = (id) => {
    return api.get(`/media/all/project/${id}`)
    .then(data => data.data)
}

const getStatuses = () => {
    return api.get('projects/statuses')
    .then(data => {
        return Object.keys(data).map(key => {
            return { 
                id: parseInt(key),
                name: data[key]
            }
        })
    });
}
const getRemodelingReasons = () => {
    return api.get('/projects/home-remodeling-reasons')
    .then(data => data.data);
}

const getPetTypes = () => {
    return api.get('/projects/home-pets')
    .then(data => data.data);
}

const remove = (id, ) => {
    return api.delete(`/projects/${id}`)
}

const setSettings = (id, type, settings) => {
    const data = [];
    Object.keys(settings).forEach( name => {
        const answer = settings[name];
        if(typeof answer !== 'undefined')
            data.push({questionType: type, questionKey: name, answer: answer})
    });
    if(!data.length)
        return Promise.resolve([]);
    return api.patch(`/question-answers/project/${id}`, {data})
    .then(_ => data);
}

const getSettings = (id) => {
     return api.get(`/question-answers/project/${id}`)
    .then(data => data.data);
}

const getEstimation = (id, forse) => {
    return api.get(`/estimate-on/${id}${forse ? '?recalculate=1' : ''}`)
    .then( 
        data => {
            return data.data || []
        }
    )
}

const getQuizConfig = name => {
    return WarcottService.getDomain(name)
    .then(
        fieldsets => {
            return fieldsets.map(fieldset => {
                fieldset.fields =  fieldset.fields.map(field => {
                    if(field.size)
                        field.size = parseInt(field.size) * 2;
                    if(field.type === FieldTypes.TEXT){
                        field = {
                            ...field, 
                            displayName: undefined, 
                            options: field.displayName
                        }
                    }
                    return field;
                });
                return fieldset;
            });
        }
    )
}

const getQuizResult = (name, data) => {
    return api.get(`/warcott/mapping/${name}`)
}

export const ProjectService = {
    signal,
    list,
    listAdvanced,
    get,
    getProjectTypes,
    getRemodelingReasons,
    getPetTypes,
    getImages,
    getStatuses,
    create,
    update,
    remove,
    getSettings,
    setSettings,
    getEstimation,

    getQuizConfig,
    getQuizResult,
};