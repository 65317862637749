import './CardHelp.scss';
import React, { Component } from 'react';
import { Card } from 'antd';
import { LocalData } from '../services';

const { Meta,  } = Card;

const HelpConfig = {
    projectDetails: {
        icon: "/images/help-home.png",
        title: 'My Home',
        text: `Tell us about the canvas we have to work with – oh and as much information as you feel is relevant. Are you a crazy cat lady? Have the worlds coolest bobblehead collection you want to show off? Running out of space in that old cabinet's junk drawer? The more you tell us, the better our team can serve you. Type away!`
    },
    projectRooms: {
        icon: "/images/help-home.png",
        title: 'My Rooms',
        text: `Most clients have certain rooms they focus their renovation on. Take a few minutes to outline each room and the components you would like to change. You don’t need to know exactly how everything will be accomplished. Our software can take the most basic of ideas to begin generating estimates and bringing your project to life. Don't worry, we'll continue to iterate down the line until it feels just right.`
    },
    projectInsp: {
        icon: "/images/help-insp.png",
        title: 'My Inspiration',
        text: `Show us some things that you love. Whether it’s one room that’s always inspired you or elements from ten different rooms you’ve found on Pinterest, we love seeing a sneak peek of your dreams.`
    },
    projectStyle: {
        icon: "/images/help-style.png",
        title: 'My Style',
        text: `Let the fun begin! We use this section for our design team get to know you. Our style quiz just takes a few minutes and helps us pair you with the best team member for your needs. The style quiz is intended to give us a better understanding of your style preferences and the designs that would best fit them. It is composed of 4 sections that will take 3-4 minutes to complete.`
    }
}

class CardHelp extends Component {
    constructor(props){
        super(props);
        this.state ={
            hide: LocalData.preferences(LocalData.preferenceType[props.name])
        }
    }

    handleHideClick = (name)=> {
        LocalData.preferences(LocalData.preferenceType[name], true);  
        this.setState({ hide: true}) 
    }

    render () {
        const { name } = this.props;
        const { hide } = this.state;
        
        if(hide)
            return null;
        
        const config = HelpConfig[name]
        return (
            <Card className="card card-help">
                <Meta
                    avatar={<img src={config.icon} alt={config.title}/>}
                    title={config.title}
                    description={config.text}
                />
                <div className="btn-link" onClick={() => this.handleHideClick(name)}>Hide</div>
            </Card>
        )
    }
}
export default CardHelp;