import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './assets/index.scss';
import './assets/notification.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { history } from './helpers';
import store from './store';
import App from './components/App';

ReactDOM.render(
    <Provider store={store}>
        <App history={history} />
    </Provider>
    ,
 document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
