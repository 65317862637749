import * as React from 'react';
import { Input, Button, message } from 'antd';
import { UserActions as actions } from '../actions';
import Form, { FormItem } from '../components/Form';
import { history } from '../helpers';
import { UserModel as model } from '../models';

class PasswordChange extends React.Component {
    state = {
        confirmDirty: false
    }

    componentDidMount() {
        const token = this.props.params.token
        actions.passwordChangeValidateToken('forgotPassword', token)
            .then(data => {
                if (data.message === 'expired') {
                    message.error(`Password already changed...`, 5)
                    history.push('/')
                }
            })
    }


    handleSubmit = (values) => {
        const data = {
            token: this.props.params.token,
            password: values.password
        }
        return actions.passwordChange(data)
            .then(data => {
                history.push('/');
            });
    }

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }

    validatePasswordConfirm = (rule, value, callback) => {
        if (value && value !== this.form.getFieldValue('password')) {
            callback(`Password doesn't match`);
        } else {
            callback();
        }
    }

    validatePassword = (rule, value, callback) => {
        if (value && this.state.confirmDirty) {
            this.form.validateFields(['confirm'])
        }
        callback();
    }

    render() {
        const thisModel = {
            password: model.password(this.validatePassword),
            confirm: model.confirm(this.validatePasswordConfirm)
        }

        return (
            <Form id="frmPasswordChange"
                initialValues={{}}
                wrappedComponentRef={(inst) => this.form = inst}
                onSubmit={this.handleSubmit}>

                <FormItem {...thisModel.password}>
                    <Input type="password"
                        placeholder="Password"
                        autoComplete="none" />
                </FormItem>

                <FormItem {...thisModel.confirm}>
                    <Input type="password"
                        placeholder="Password"
                        autoComplete="none"
                        onBlur={this.handleConfirmBlur} />
                </FormItem>

                <Button
                    type="danger"
                    size="large"
                    htmlType="submit">Change</Button>
            </Form>
        )
    }
}

export default PasswordChange;